export const fetchData = (url, options = {}) =>
  fetch(url, options)
    .then(response => {
      return response.status === 204 ? {} : response.json();
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
