import { Box, Button, CircularProgress } from "@mui/material";

const WSExportButton = ({ error, isLoading, label, uploadData}) => {
  return(<>
    <Box
      sx={{
        alignItems: 'start',
        backgroundColor: 'light',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        marginTop: 2,
        padding: 2,
      }}
    >
      <Button
        variant="contained"
        color={"info"}
        style={{height: '50px', width: '300px'}}
        onClick={() => uploadData()}
      >
        {isLoading ? <CircularProgress/> : label}
      </Button>
    </Box>
    {error && <div style={{color: 'red', marginLeft: '19px'}}> {error} </div>}
  </>)
};

export default WSExportButton;
