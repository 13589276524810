import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {Alert, Modal, Tooltip, useTheme} from '@mui/material';
import DvrIcon from '@mui/icons-material/Dvr';
import Box from '@mui/material/Box';
import {Button} from 'react-admin';
import {DataGrid} from '@mui/x-data-grid';
import {getRelaysUsers} from './api';
import CircularProgress from '@mui/material/CircularProgress';
import PersonIcon from '@mui/icons-material/Person';
import moment from 'moment/moment';
import {useNavigate} from 'react-router-dom';

const style = {
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  left: '50%',
  pb: 3,
  position: 'absolute',
  pt: 2,
  px: 4,
  textAlign: 'center',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const ORDERS_STATE_LOADING = 'loading';
const ORDERS_STATE_LOADED = 'loaded';
const ORDERS_STATE_ERROR = 'error';

export const ModalReplayUsers = ({offerReplayId, replayId, version}) => {
  const [state, setState] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const ordersGridColumns = [
    {
      field: 'firstname',
      headerName: 'Prénom',
      width: 250,
      sortable: false
    },
    {
      field: 'lastname',
      headerName: 'Nom',
      width: 250,
      sortable: false
    },
    {
      field: 'userId',
      headerName: 'Id utilisateur',
      width: 200,
      sortable: false,
      renderCell: ({value, row: {userUuid}}) => <>
        {value}
        <Tooltip
          title="Voir le client dans le backoffice"
          placement="bottom"
          arrow
        >
          <PersonIcon
            fontSize="small"
            onClick={() => navigate(`/users/${encodeURIComponent(`/users/${userUuid}`)}/show`)}
            sx={{float: 'right', ml: 1, cursor: 'pointer'}}
          />
        </Tooltip>
      </>
    },
    {
      field: 'addedAt',
      headerName: 'Ajouté le',
      width: 200,
      sortable: false,
      renderCell: ({value}) => value ? moment(value).format('DD/MM/YYYY à HH[h]mm') : ''
    },
    {
      field: 'expirationAt',
      headerName: 'Expiration le',
      width: 200,
      sortable: false,
      renderCell: ({value}) => value ? moment(value).format('DD/MM/YYYY à HH[h]mm') : ''
    },
  ];

  const loadOrders = useCallback(() => {
    setState(ORDERS_STATE_LOADING);
    setData(null);
    getRelaysUsers(replayId, offerReplayId).then((data) => {
      setState(ORDERS_STATE_LOADED);
      setData(data)
    }).catch(error => {
      setState(ORDERS_STATE_ERROR);
      console.error('Error', error)
    });
  }, [replayId, offerReplayId, version]);

  useEffect(() => {
    if (!show) {
      return;
    }
    loadOrders();
  }, [show, replayId, version]);

  return <>
    <Tooltip
      title="Affiche les clients de cette version."
      placement="top"
      arrow
    >
      <DvrIcon fontSize="small" sx={{float: 'right', cursor: 'pointer', ml: 1}} onClick={() => setShow(true)}/>
    </Tooltip>
    <Modal
      open={show}
    >
      <Box sx={{...style, width: '90%'}}>
        {state === ORDERS_STATE_LOADING && <CircularProgress/>}

        {state === ORDERS_STATE_ERROR && <Alert severity="error">
          Impossible de récupérer les clients.
          Veuillez vérifier que vous êtes bien connecté.
          <br/>
          <Button
            onClick={loadOrders}
            sx={{marginTop: 2}}
            variant="contained"
          >Recommencer</Button>
        </Alert>}

        {state === ORDERS_STATE_LOADED && <>
          <h2 style={{color: theme.palette.text.primary}}>Clients liés à la version {version}</h2>
          <DataGrid
            columns={ordersGridColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 100,
                },
              },
            }}
            rows={data}
            disableColumnFilter
            disableColumnMenu
            hideFooter
          />
        </>}

        <div>
          <Button
            onClick={() => setShow(false)}
            sx={{mt: 2, px: 1}}
            variant="contained"
          >Fermer</Button>
        </div>
      </Box>
    </Modal>
  </>;
}
