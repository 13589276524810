import {getAuthorizationHeader} from '../../utils/CognitoHelpers';

const generateHeaders = async (extraHeaders = {}) => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  ...(await getAuthorizationHeader()),
  ...(extraHeaders || {}),
})

const createGetOptions = async (extraHeaders = {}) => ({
  method: 'GET',
  headers: await generateHeaders(extraHeaders),
});

const createPostOptions = async (extraHeaders = {}, body = undefined) => ({
  method: 'POST',
  headers: await generateHeaders(extraHeaders),
  body: body ? JSON.stringify(body) : undefined,
});

const checkResponse = (response) => {
  if (response.status < 300 && response.status >= 200) {
    return response.json();
  }
  return Promise.reject(response);
};

export const getUser = async (userId) => fetch(
  `${process.env.REACT_APP_ENTRYPOINT}/users/${encodeURIComponent(userId)}`,
  await createGetOptions()
).then(checkResponse);

export const doStartLogin = async (userId) => fetch(
  `${process.env.REACT_APP_ENTRYPOINT}/users/${encodeURIComponent(userId)}/admin-login`,
  await createPostOptions()
).then(checkResponse);

export const getAdminConnectedAs = async () => fetch(
  `${process.env.REACT_APP_ENTRYPOINT}/admin/connected-as`,
  await createGetOptions()
).then(checkResponse);

export const postDisconnectAdminUser = async () => fetch(
  `${process.env.REACT_APP_ENTRYPOINT}/admin/disconnected-as`,
  await createPostOptions()
).then(checkResponse);

export const postShareLocalStorage = async (data) => fetch(
  `${process.env.REACT_APP_ENTRYPOINT}/admin/store-local-storage`,
  await createPostOptions({}, { data })
).then(checkResponse);

