// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.amplify-label {
  color: white;
}
.data-amplify-router  {
  margin-top: 30px;
}

[data-amplify-authenticator] [data-amplify-router]  {
  margin-top: 50px;
}

.amplify-input {
  border-radius: 8px;
  background: white;
}

.amplify-button {
  --amplify-internal-button-color: #eae2e2;
}

.amplify-heading--3 {
  color: white;
}

.amplify-text.amplify-text--error {
  color: #FF0000;
}

.amplify-flex[data-amplify-copy] {
  color: white;
}

.amplify-flex[data-amplify-copy-svg] path {
  fill: white
}
`, "",{"version":3,"sources":["webpack://./src/components/amplify/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;AACF","sourcesContent":[".amplify-label {\n  color: white;\n}\n.data-amplify-router  {\n  margin-top: 30px;\n}\n\n[data-amplify-authenticator] [data-amplify-router]  {\n  margin-top: 50px;\n}\n\n.amplify-input {\n  border-radius: 8px;\n  background: white;\n}\n\n.amplify-button {\n  --amplify-internal-button-color: #eae2e2;\n}\n\n.amplify-heading--3 {\n  color: white;\n}\n\n.amplify-text.amplify-text--error {\n  color: #FF0000;\n}\n\n.amplify-flex[data-amplify-copy] {\n  color: white;\n}\n\n.amplify-flex[data-amplify-copy-svg] path {\n  fill: white\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
