import './style.css';
import React from 'react';

const Modal = ({ children, onClose }) => {
 return (<div className={'modal-overlay'}>
    <div className={'modal-container'}>
      <span className="close-button" onClick={onClose}>
        &times;
      </span>
      {children}
    </div>
 </div>)
}

export default Modal;
