import React from 'react';
import { InputGuesser, EditGuesser } from "@api-platform/admin";

const EditTitle = ({ record }) => {
  return <span>Editer un tag - {record ? `${record.name}` : ''}</span>;
};

const EditTags = (({ classes, ...props }) => (
  <EditGuesser undoable={false} {...props} title={<EditTitle />}>
    <InputGuesser source={'name'} />
  </EditGuesser>
));

export default EditTags;
