import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, AlertTitle, Box, Button, CircularProgress, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { doStartLogin, getAdminConnectedAs, getUser, postDisconnectAdminUser } from './api';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Auth } from 'aws-amplify';
import LockPersonIcon from '@mui/icons-material/LockPerson';

export const Connection = () => {
  const { userId, page } = useParams();
  const [user, setUser] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isConnecting, setConnecting] = useState(false);
  const [isLogged, setLogged] = useState(false);
  const [expiration, setExpiration] = useState(null);
  const [expirationDiff, setExpirationDiff] = useState('(?)');

  const refreshMeConnected = useCallback(() => {
    setLogged(false);
    getAdminConnectedAs().then(result => {
      setLogged(result.uuid === userId);
    })
  }, [userId]);

  const loadUserInfo = useCallback((userId) => {
    return getUser(userId)
      .then(setUser)
      .catch((error) => {
        console.error(error);
        setUser(false);
      });
  }, []);

  useEffect(() => {
    if (!userId) {
      return;
    }
    loadUserInfo(userId);
    refreshMeConnected();
    Auth.currentSession().then(session => {
      setExpiration(session.getIdToken().payload.exp);
    })
  }, [userId]);

  const who = useMemo(() => {
    if (!user) {
      return null;
    }
    return `${user.firstname} ${user.lastname} (${user.email})`;
  });

  useEffect(() => {
    setProgress(0);
    if (!user) {
      return;
    }
    const duration = 10;
    const interval = 100;
    const intervalId = setInterval(() => {
      setProgress((progress) => {
        const result = progress + 100 / duration / (1000 / interval);
        if (result >= 100) {
          clearInterval(intervalId);
          setProgress(() => 100);
        }
        return result;
      });
    }, interval);

    return () => clearInterval(intervalId);
  }, [user]);

  const isActivated = useMemo(() => {
    if (progress >= 100) {
      return true;
    }

    return false;
  }, [progress]);

  const hostname = useMemo(() => {
    const {hostname} = new URL(process.env.REACT_APP_LINK);
    return hostname;
  }, []);

  const goWooskillWebSite = useCallback(() => {
    window.open('/#/go-to-website', '_blank')
  }, []);

  const doLogin = useCallback(() => {
    setLogged(false);
    setConnecting(true);
    doStartLogin(userId)
      .then(() => {
        setConnecting(false);
        goWooskillWebSite();
        document.location.reload();
      })
      .catch(error => {
        console.error(error);
        setConnecting(false);
        setLogged(false);
      });
  }, [userId, goWooskillWebSite]);

  const doLogout = useCallback(() => {
    postDisconnectAdminUser().then(() => {
      document.location.reload();
    }, []);
  }, []);

  useEffect(() => {
    const secondsToText = (second) => (second - (second %= 60)) / 60 + (9 < second ? 'min et ' : 'min et 0') + second;
    const interval = setInterval(() => {
      const diff = (new Date(expiration * 1000)).getTime() - (new Date()).getTime();
      if (diff < 0) {
        setExpirationDiff('La session est EXPIRÉ. Recharger la page pour recommencer.');
        return;
      }
      setExpirationDiff(`La session expirera dans ${secondsToText(Math.round(diff / 1000))} sec.`);
    }, 1000);

    return () => clearInterval(interval);
  }, [expiration]);

  return <div>
    <Paper elevation={3}>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'light',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          marginTop: 5,
          padding: 2,
        }}
      >
        {(!user || isConnecting) && <CircularProgress/>}
        {!isConnecting && !!user && !isLogged && <>
          <Alert severity="warning" sx={{ma: 2}}>
            <AlertTitle>ATTENTION !</AlertTitle>
            Vous êtes sur le point de vous connecter à la place de <strong>{who}</strong>
            <br/>
            Veuillez prendre note des éléments suivants :
            <ul>
              <li>Veuillez vous déconnecter du site de Wooskill et fermer l'ensemble des onglets/fenêtres ouvert sur
                Wooskill.
              </li>
              <li>Veillez à éviter toutes actions en même temps que cette personnes (Typiquement, ne modifiez pas en
                meme temps une offre)
              </li>
              <li>Chaque action réalisée est au nom de <strong>{who}</strong> et peut provoquer des envois de mails à
                sa place !
              </li>
              <li>N&apos;utilisez pas la messagerie pour envoyer des messages sans son accord.</li>
              <li>Pensez à vous déconnecter une fois terminé.</li>
              <li>Lors que vous allez sur Wooskill dans une nouvelle page, vérifiez bien que vous n&apos;êtes plus
                connecté.
              </li>
            </ul>

            <strong>{expirationDiff}</strong>.
            <br/><br/>
            (Vous souhaitez un délais plus long ? Reconnectez-vous au backoffice pour avoir un jeton plus long ou
            attendez la fin et actualisez la page. Max: 1h)
          </Alert>


          <Button
            disabled={!isActivated}
            variant="contained"
            sx={{maxWidth: '500px'}}
            onClick={() => doLogin()}
          >
            {!isActivated && <CircularProgress variant="determinate" value={100 - progress}/>}
            {isActivated && <LockOpenIcon/>}

            Me connecter à la place de {who}
          </Button>

        </>}

        {isLogged && user && <>
          <Alert severity="info" sx={{ma: 2}}>
            <AlertTitle>CONNECTÉ !</AlertTitle>
            Vous êtes connecté à la place de <strong>{who}</strong>
          </Alert>

          <Button
            variant="contained"
            sx={{maxWidth: '500px'}}
            onClick={() => goWooskillWebSite()}
          >
            <OpenInNewIcon sx={{mr: 1}}/>
            Aller sur {hostname}
          </Button>

          <Button
            variant="contained"
            sx={{maxWidth: '500px'}}
            onClick={() => doLogout()}
          >
            <LockPersonIcon sx={{mr: 1}}/>
            Déconnecter Wooskill de {who}
          </Button>
        </>}
      </Box>
    </Paper>
  </div>;
}
