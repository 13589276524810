import { InputLabel, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';

/* options are like this
[
  {name: 'option1', id: 1},
  {name: 'option2', id: 2},
]
 */
const CustomSelectInput = ({ onChange, label, labelId, options, value }) => {
  return <>
    <InputLabel id={labelId}>{label}</InputLabel>
    <Select
      labelId={labelId}
      value={value}
      label={label}
      onChange={onChange}
    >
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}

    </Select>
  </>
}

export default CustomSelectInput;
