import { styled } from "@mui/material";

export const BadgeContainer = styled('div')(({colorPrimary, colorSecondary}) => ({
    background: 'linear-gradient(45deg, ' + colorPrimary + ',' + colorSecondary + ')',
    borderRadius: 10,
    padding: '1em',
}));

export const Text = styled('div')(({fontWeight, fontSize, align}) => ({
    fontSize: fontSize ? fontSize + 'em' : 14,
    fontWeight: fontWeight ? fontWeight : 'normal',
    color: 'white',
    textAlign: align ? align : 'inherit'
}));