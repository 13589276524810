import React, { useEffect, useState } from 'react';
import {
  TextInput,
  Filter,
  DateInput,
  SelectField,
  DateField,
  useRecordContext,
  BulkExportButton,
  NumberField,
  List,
  TextField,
  Datagrid,
  FunctionField
} from 'react-admin';
import CreateIcon from '@mui/icons-material/Create';

import { statusChoices } from "../utils";
import ListOffers from "../offers/ListOffers";
import { Link } from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";

const UrlFieldWooskill = ( props ) =>
{
    const { source, target, rel } = props;
    const record = useRecordContext(props);
    const value = record && record[source];

    if (value === null) {
      return null;
    }

    return (
      <Link href={`${process.env.REACT_APP_LINK}${value}`} target={target} rel={rel}>
        {value}
      </Link>
    );
};

const ProfilesGridFilters = props => (
  <Filter {...props}>
    <TextInput label="Nom" source="name"/>
    <TextInput label="Url" source="slug"/>
    <TextInput label="Id" source="id"/>
    <DateInput
      source="createdAt[after]"
      label="Date de création (Début)"
      options={{
        mode: 'portrait',
        locale: 'nl',
      }}
    />
    <DateInput
      source="createdAt[before]"
      label="Date de création (Fin)"
      defaultValue={props.date}
      options={{
        mode: 'portrait',
        locale: 'nl',
        defaultDate: props.date,
      }}
    />
  </Filter>
);

const PostBulkActionButtons = props => (
  <>
    <BulkExportButton {...props} />
  </>
);

const RecordGetter = ({onRecord}) => {
  const record = useRecordContext();
  useEffect(() => {
    onRecord(record)
  }, [record]);
}

const ListProfiles = (({ ...props}) => {
  const [record, setRecord] = useState(null);
  const ShowOffers = ({ id }) => (
    <ListOffers
      filter={{ profile_uuid: id }}
      resource="offers"
      pagination={false}
      filters={<ProfilesGridFilters date={new Date()}/>}
      title="offres"
      exporter={false}
    />
  );

  return (
    <List
      filters={<ProfilesGridFilters date={new Date()}/>}
      bulkActionButtons={<PostBulkActionButtons />}
      title="Les profils"
      expand={<ShowOffers />}
      {...props}
    >
      <Datagrid>
        <RecordGetter onRecord={setRecord} />
        <NumberField label={'Id'} source={'db_id'}/>
        <TextField label={'Nom'} source={'name'}/>
        <DateField
          label="Date de création"
          source={'created_at'}
          showTime
          locales="fr-FR"
        />
        <NumberField label={'Nombre d\'offres'} source={'nb_offers'}/>
        <NumberField label={'Score Hubspot'} source={'score_hubspot'}/>
        <UrlFieldWooskill
          source={'url'}
          target='_blank' // New window
          rel="noopener noreferrer" // For security
        />
        <SelectField source="status" choices={statusChoices}/>
        <FunctionField
          label="voir"
          render={record =>
            <a style={{marginLeft: 20}} href={`/v2/profiles/show.html?uuid=${record.uuid}&mode=show`}>
              <RemoveRedEye color="primary"/>
            </a>
          }
        />
        <FunctionField
          label="éditer"
          render={record =>
            <a href={`/v2/profiles/show.html?uuid=${record.uuid}&mode=edit`}>
              <CreateIcon color="primary"/>
            </a>
          }
        />
      </Datagrid>
    </List>
  );
});

export default (ListProfiles);
