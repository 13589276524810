import React, { useCallback, useEffect, useState } from 'react';
import {
  Datagrid,
  downloadCSV,
  FunctionField,
  ReferenceArrayField,
  ReferenceField,
  TextField,
  useNotify,
  useRecordContext,
} from 'react-admin';
import {FieldGuesser, InputGuesser, ShowGuesser} from '@api-platform/admin';
import { dateLetterFormatTimeWithYear } from '../../utils/Date';
import { Button } from '@mui/material';
import * as moment from 'moment';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const ShowTitle = ({ record }) => {
  return <span>Code promo - {record ? `${record.title}` : ''}</span>;
};

const RecordGetter = ({onRecord}) => {
  const record = useRecordContext();
  useEffect(() => {
    onRecord(record)
  }, [record]);
}

const ShowPromotions = (({ classes, ...props }) => {
  const [record, setRecord] = useState(null);
  const notify = useNotify();

  const exportToExcel = useCallback((e) => {
    e.preventDefault();

    const postsForExport = () => {
      const dateString = moment(record.created_at).format('DD-MM-YYYY H:mm');
      const limitDate = moment(record.limit_date).format('DD-MM-YYYY H:mm');
      return {
        Code: record.title,
        'Date_limite_du_code': limitDate,
        'Date_de_creation': dateString,
        Valeur: `${record.value}%`,
      };
    };
    const csv = convertToCSV({
      data: postsForExport,
    });

    downloadCSV(
      csv,
      `promotioncode_${record.title.split(' ').join('_')}_${moment().format(
        'DDMMYYYYHmm',
      )}`,
    );
    notify('Succès : Le csv a correctement été exporté.', 'info');
  }, [record, notify]);

  return (
    <ShowGuesser {...props} title={<ShowTitle/>}>
      <RecordGetter onRecord={setRecord}/>
      <FunctionField
        label="id"
        render={record => `${record.id.split('/')[2]}`}
      />

      <FieldGuesser source={'title'} addLabel={true}/>
      <FieldGuesser source={'value'} addLabel={true}/>
      <FieldGuesser source={'count_code_to_generate'} addLabel={true}/>
      <FieldGuesser source={'count_code_used'} addLabel={true}/>

      {!!record && !!record?.professional && (
        <>
          <div>Skiller</div>
          <a href={`/#/users/%2Fusers%2F${record?.professional['@id'].split('/')[2]}/show`}>
            {record.professional?.public_name}
          </a>
        </>)
      }

      {!!record && !!record?.offer && (
        <>
          <div>Offre</div>
          <a href={`/#/offers/%2Foffers%2F${record?.offer['@id'].split('/')[2]}/show`}>
            {record.offer?.title}
          </a>
        </>)
      }

      <ReferenceArrayField
        label="Les codes promos"
        reference="promotions"
        source="promotion_codes">
        <Datagrid>
          <FunctionField label="Code" render={record => `${record.code}`} />
          <FunctionField
            label="Date limite"
            render={record =>
              `${dateLetterFormatTimeWithYear(new Date(record.limit_date))}`
            }
          />
          <ReferenceField
            label="Skiller"
            link="show"
            source="used_by"
            reference="users">
            <TextField source="public_name" />
          </ReferenceField>
          <FunctionField
            label="Date d'utilisation"
            render={record =>
              `${
                record.used_at === null
                  ? 'Non utilisé'
                  : dateLetterFormatTimeWithYear(new Date(record.used_at))
              }`
            }
          />
          <FunctionField
            label="Date de création"
            render={record =>
              `${dateLetterFormatTimeWithYear(new Date(record.created_at))}`
            }
          />
        </Datagrid>
      </ReferenceArrayField>
      <FieldGuesser source={'created_at'} addLabel={true} />
      <FieldGuesser source={'updated_at'} addLabel={true} />
      <FieldGuesser
        source="is_divisible"
        label="Coupon sécable"
      />

      {(record?.owner) && <div>
        Propriétaire du code promo: {record.owner.email}
      </div>
      }

      <Button variant="contained" onClick={exportToExcel} color="primary">
        Exporter au format .csv
      </Button>
    </ShowGuesser>
  );
});

export default ShowPromotions;
