export const sexeChoices = [
  { id: 'M', name: 'Homme' },
  { id: 'F', name: 'Femme' },
  { id: 'B', name: 'Binaire' },
];

export const rolesChoicesUser = [
  { id: 'ROLE_USER', name: 'Utilisateur' },
  { id: 'ROLE_PRO', name: 'Skiller' },
];

export const rolesChoices = [
  { id: 'ROLE_SUPER_ADMIN', name: 'Super admin' },
  { id: 'ROLE_ADMIN', name: 'Admin' },
];

export const communicationTypesChoice = [
  { id: 'video', name: 'Visio' },
  { id: 'f2f_client_location', name: 'Chez le client' },
  { id: 'f2f_professional_location', name: 'Chez le Skiller' },
];

export const statusChoices = [
  { id: 'enabled', name: 'actif' },
  { id: 'disabled', name: 'inactif' }
];

export const rolesUser = {
  ROLE_USER: 'Utilisateur',
  ROLE_PRO: 'Skiller',
};
