import { fetchData } from "../overrides/Fetch";
import { getAuthorizationHeader } from '../utils/CognitoHelpers';

export const reindexOffers = async () => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/admin/index/offers`;
  let headers;

  headers = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json',
      ...(await getAuthorizationHeader()),
    },
  };

  return fetchData(entrypoint, headers);
}

export const commandSMS = async () => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/admin/sms_reminder/command`;
  let headers;

  headers = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json',
      ...(await getAuthorizationHeader()),
    },
  };

  return fetchData(entrypoint, headers);
}

export const commandMail = async () => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/admin/mail_reminder/command`;
  let headers;

  headers = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json',
      ...(await getAuthorizationHeader()),
    },
  };

  return fetchData(entrypoint, headers);
}

export const commandPayment = async () => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/admin/payment/command`;
  let headers;

  headers = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json',
      ...(await getAuthorizationHeader()),
    },
  };

  return fetchData(entrypoint, headers);
}
