import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Tooltip} from '@mui/material';

export const ReplayTaskType = ({type}) => {

  const typeMapping = {
    'capture': 'Enregistrement du live',
    'concatenation_artefact': 'Création du fichier brut',
    'hack_async_audio_video': 'Fix synchro audio/vidéo',
    'optimize': 'Optimisation vidéo',
    'client_preview': 'Aperçu vidéo',
  }
  const descriptionMapping = {
    'capture': 'Client virtuel dans le live pour enregistrer le flux de données.',
    'concatenation_artefact': 'Concaténation des micros fichiers vidéos fournis par AWS en un seul fichier vidéo brut.',
    'hack_async_audio_video': 'Résolution du problème de synchronisation entre le flux audio et le flux vidéo (Bug connu par AWS).',
    'optimize': 'Optimisation du fichier vidéo brut pour le rendre compatible avec les navigateurs et ajout du logo Wooskill.',
    'client_preview': 'Lorsque le client publie son replay, il peut choisir un extrait vidéo, cette étape découpe la vidéo et ajoute le logo Wooskill.',
  }

  const typeLabel = typeMapping[type] || type;

  if (descriptionMapping[type]) {
    return <Tooltip title={descriptionMapping[type]} placement="bottom" arrow>
      <HelpOutlineIcon fontSize="small" sx={{float: 'left', mr: 1}}/>
      {typeLabel}
    </Tooltip>
  }

  return typeLabel;
}
