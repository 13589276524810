import { fetchData } from '../overrides/Fetch';
import { getAuthorizationHeader } from '../utils/CognitoHelpers';

export const fetchDomains = async filters => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/domains?${filters}`;
  let headers;
  
  headers = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json',
      ...(await getAuthorizationHeader()),
    },
  };

  return fetchData(entrypoint, headers);
}

export const fetchDomainsByCategory = async id => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/domains/?categories=${id}`;
  let headers;

  headers = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json',
      ...(await getAuthorizationHeader()),
    },
  };

  return fetchData(entrypoint, headers);
}
