import React from 'react';
import {
  DateField,
  FunctionField,
  Datagrid,
  EditButton,
  NumberField,
  ReferenceManyField,
  ShowButton,
  TabbedShowLayout,
  Tab,
  TextField,
  useRecordContext
} from 'react-admin';
import { FieldGuesser, ShowGuesser } from '@api-platform/admin';
import { Link } from "@mui/material";
import { getLocales } from "../../providers/i18nProvider";
import "./profile.css"
import SponsoredPartiesShow from "./SponsoredPartiesShow";
import { useParams } from 'react-router-dom';

const UrlFieldWooskill = ( props ) =>
{
  const { source, target, rel } = props;
  const record = useRecordContext(props);
  const value = record && record[source];

  if (value === null) {
    return null;
  }

  return (
    <Link href={`${process.env.REACT_APP_LINK}${value}`} target={target} rel={rel}>
      {value}
    </Link>
  );
};

const ShowProfiles = ({ classes, ...props }) => {
  const { id } = useParams();
  return (
    <ShowGuesser {...props}>
      <TabbedShowLayout>
        <Tab label="Informations">
          <FunctionField
            addLabel={false}
            source="profile_picture_url"
            render={record => (
              <img
                style={{
                  background: 'black',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
                width="120px"
                height="120px"
                src={`${process.env.NEXT_PUBLIC_MEDIA_SERVER}/${record.profile_picture_url}`}
                alt="profil"
              />
            )}
          />
          <FieldGuesser source={'id'} addLabel={true}/>
          <FieldGuesser source={'presentation_title'} label={'Titre de présentation'} addLabel={true}/>
          <FieldGuesser source={'presentation_description'} label={'Description de présentation'} addLabel={true}/>
          <FieldGuesser source={'total_vue_count'} label={'Nombre de visite'} addLabel={true}/>
          <UrlFieldWooskill source={'url'} label={'Lien'} addLabel={true}/>
          <DateField
            source="last_activity_at"
            label="Date de dernière activité"
            locales={getLocales}
            showTime
          />
          <DateField source="created_at" label="Date de création" locales={getLocales} showTime/>
          <FieldGuesser label={'Score Hubspot'} source={'score_hubspot'} addLabel={true}/>
          <FieldGuesser label={"Highlighted"} source={'highlighted'} addLabel={true}/>
          <FieldGuesser label={"Skiller certifié"} source={'verified_profile'} addLabel={true}/>
          <FieldGuesser label={"Skiller influenceur"} source={'is_influencer'} addLabel={true}/>
          <FieldGuesser label={"Skiller mis en avant sur la HomePage"} source={'homepage_catalog_highlighted'} addLabel={true}/>
        </Tab>
        <Tab label={`Offres`} path="offers">
          <ReferenceManyField reference="offers" target="profile_uuid" label={false}>
            <Datagrid>
              <NumberField label={'Id'} source={'db_id'}/>

              <TextField label={'Titre'} source={'title'}/>
              <FieldGuesser label={'Status'} source={'status'}/>
              <FieldGuesser source={'type'} label="Type"/>
              <NumberField label={'Nombre de visite'} source={'vue_count'}/>

              <DateField label={'Date de dernière modification'} source={'updated_at'}/>
              <UrlFieldWooskill
                source={'url'}
                target='_blank' // New window
                rel="noopener noreferrer" // For security
              />
              <DateField label={'date de suppression'} source={'deleted_at'}/>
              <FieldGuesser label={'Mis en avant'} source={'is_highlighted'}/>
              <ShowButton/>
              <EditButton/>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Filleuls">
          <SponsoredPartiesShow id={id} />
        </Tab>
      </TabbedShowLayout>
    </ShowGuesser>
  )
};

export default ShowProfiles;