import { Box, Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { getDataToExport } from "../../../routeApi/exports.js";
import { downloadCSV } from "react-admin";
import * as moment from "moment";
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const BasicExportButton = ({ exportParamName, fileLabel, label, title }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const uploadData = () => {
    setIsLoading(true);
    if (error) {
      setError(false);
    }

    getDataToExport(exportParamName)
      .then((response) => {
        if (response.code_error) {
          setError('une erreur est survenue');
          return;
        }
        const csv = convertToCSV({
          data: response,
        });

        downloadCSV(
          csv,
          `${fileLabel}_${moment().format('DDMMYYYYHmm')}`
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return(<>
    <h2>{title}</h2>
    <Box
      sx={{
        alignItems: 'start',
        backgroundColor: 'light',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        marginTop: 2,
        padding: 2,
      }}
    >
      <Button
        variant="contained"
        color={"info"}
        style={{height: '50px', width: '300px'}}
        onClick={() => uploadData()}
      >
        {isLoading ? <CircularProgress/> : label}
      </Button>
    </Box>
    {error && <div style={{color: 'red', marginLeft: '19px'}}> {error} </div>}
  </>)
}

export default BasicExportButton;
