import { getAuthorizationHeader } from '../utils/CognitoHelpers';
import { fetchData } from '../overrides/Fetch';

export const resetUserPassword = async userUuid => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/admin/reset_password/${userUuid}`;
  let headers;

  headers = {
    method: 'PATCH',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/merge-patch+json',
      ...(await getAuthorizationHeader()),
    },
    body: JSON.stringify({})
  };

  return fetchData(entrypoint, headers);
};
