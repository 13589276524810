import * as React from 'react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import {useNavigate, useParams} from 'react-router-dom';
import {
  Alert,
  Button,
  CardActionArea,
  CardMedia,
  ListItemIcon,
  ListItemText,
  Menu,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DownloadIcon from '@mui/icons-material/Download';
import {activateReplay, getRelaysInformation} from './api';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import 'moment/locale/fr';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import BallotIcon from "@mui/icons-material/Ballot";
import PersonIcon from '@mui/icons-material/Person';
import {ReplayStatus} from './ReplayStatus';
import videojs from 'video.js'
import 'video.js/dist/video-js.css';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector/dist/videojs-hls-quality-selector.css';
import {DataGrid} from '@mui/x-data-grid';
import {ReplayTaskAws} from './ReplayTaskAws';
import {ReplayTaskType} from './ReplayTaskType';
import {ReplayTaskStatus} from './ReplayTaskStatus';
import DvrIcon from '@mui/icons-material/Dvr';
import {ModalReplayUsers} from './ModalReplayUsers';

moment.locale('fr');

const REPLAY_STATE_LOADING = 'loading';
const REPLAY_STATE_LOADED = 'loaded';
const REPLAY_STATE_ERROR = 'error';

const INDEX_REPLAY = 0;
const INDEX_VERSIONS = 1;
const INDEX_TECHNICAL = 2;

const Replay = () => {
  const [state, setState] = useState(REPLAY_STATE_LOADING);
  const [data, setData] = useState(null);
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [recordTabIndex, setRecordTabIndex] = useState(0);
  const buttonActionRef = useRef();
  const {replayId, page} = useParams();
  const videoRef = useRef();
  const playerRef = useRef();
  const navigate = useNavigate();

  const liveAt = useMemo(() => {
    if (state !== REPLAY_STATE_LOADED || !data?.live?.liveAt) {
      return null;
    }
    return moment(data.live.liveAt)
      .format('LLLL')
      .replace(':', 'h');
  }, [data, state])

  const loadReplay = useCallback(() => {
    setState(REPLAY_STATE_LOADING);
    setData(null);
    getRelaysInformation(replayId).then((data) => {
      setState(REPLAY_STATE_LOADED);
      setData(data)
    }).catch(error => {
      setState(REPLAY_STATE_ERROR);
      console.error('Error', error)
    });
  }, [replayId]);

  const doActivateReplay = useCallback(() => {
    setState(REPLAY_STATE_LOADING);
    activateReplay(replayId).then(() => {
      setState(REPLAY_STATE_LOADED);
      loadReplay()
    }).catch(error => {
      alert('Impossible de rendre disponible le replay au skiller.')
      console.error('Error', error);
      loadReplay()
    });
  }, [replayId, loadReplay])

  useEffect(() => {
    if (!replayId) {
      return;
    }
    loadReplay();
  }, [replayId]);

  useEffect(() => {
    if (!data?.record?.src || !videoRef.current || recordTabIndex !== INDEX_REPLAY) {
      return;
    }
    setTimeout(() => {
      if (!videoRef.current) {
        return;
      }
      playerRef.current = videojs(videoRef.current, {
        autoplay: true,
        controls: true
      });
      playerRef.current.crossOrigin('anonymous')
      playerRef.current.src({ type: 'application/x-mpegURL', src: data.record.src });
    }, 1000);
    return () => {
      if (typeof playerRef.current?.dispose !== 'function') {
        return;
      }

      playerRef.current.dispose();
      playerRef.current = null;
    };
  }, [data?.record?.src, recordTabIndex]);


  const downloadFile = useCallback((url, fileName) => {
    fetch(url)
      .then(resp => resp.status === 200 ? resp.blob() : Promise.reject('Invalid file'))
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const temporaryLink = document.createElement('a');
        temporaryLink.style.display = 'none';
        temporaryLink.href = url;
        temporaryLink.download = fileName;
        document.body.appendChild(temporaryLink);
        temporaryLink.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert('Impossible de télécharger le fichier'));
  }, [])

  const technicalGridColumns = useMemo(() => [
    {
      field: 'type',
      headerName: 'Tâche',
      width: 250,
      sortable: false,
      renderCell: ({value}) => <ReplayTaskType type={value}/>
    },
    {
      field: 'status',
      headerName: 'Etat',
      width: 130,
      sortable: false,
      renderCell: ({value}) => <ReplayTaskStatus value={value}/>
    },
    {
      field: 'awsInformation',
      headerName: 'Service AWS',
      width: 250,
      sortable: false,
      renderCell: ({value}) => !!value && <ReplayTaskAws information={value}/>
    },
    {
      field: 'startAt',
      headerName: 'Début de la tâche',
      width: 150,
      sortable: false,
      renderCell: ({value}) => value ? moment(value).format('DD/MM/YYYY à HH[h]mm') : ''
    },
    {
      field: 'endAt',
      headerName: 'Find de la tâche',
      width: 150,
      sortable: false,
      renderCell: ({value}) => value ? moment(value).format('DD/MM/YYYY à HH[h]mm') : ''
    },
    {
      field: 'progress',
      headerName: 'Progression',
      width: 150,
      sortable: false,
      renderCell: ({value, row: {status}}) => {
        if (status === 'in-progress') {
          return value || value === 0 ? `${value} %` : ``
        }
        return '';
      }
    }
  ], []);

  const versionGridColumns = useMemo(() => [
    {
      field: 'snapshotVersion',
      headerName: 'Version',
      width: 70,
      sortable: false
    },
    {
      field: 'title',
      headerName: 'Titre du replay',
      width: 250,
      sortable: false,
      renderCell: ({value}) => <Tooltip
        title={value}
        placement="bottom"
        arrow
      >{value}</Tooltip>
    },
    {
      field: 'publishedStartAt',
      headerName: 'Publié le',
      width: 150,
      sortable: false,
      renderCell: ({value}) => value ? moment(value).format('DD/MM/YYYY à HH[h]mm') : ''
    },
    {
      field: 'publishedEndAt',
      headerName: 'Jusqu\'au',
      width: 150,
      sortable: false,
      renderCell: ({value}) => value ? moment(value).format('DD/MM/YYYY à HH[h]mm') : ''
    },
    {
      field: 'expirationAt',
      headerName: 'Expiration le',
      width: 150,
      sortable: false,
      renderCell: ({value}) => value ? moment(value).format('DD/MM/YYYY à HH[h]mm') : ''
    },
    {
      field: 'shareLiveAttendees',
      headerName: 'Partagé',
      width: 100,
      sortable: false,
      renderCell: ({value}) => value ? 'Oui' : 'Non'
    },
    {
      field: 'skillerExpectedIncome',
      headerName: 'Rém. Skiller',
      width: 100,
      sortable: false,
      renderCell: ({value, row: {currency}}) => value ? ` ${value} ${currency}` : 'Gratuit'
    },
    {
      field: 'vat',
      headerName: 'TVA',
      width: 100,
      sortable: false,
    },
    {
      field: 'usersCount',
      headerName: 'Nb users',
      width: 120,
      sortable: false,
      renderCell: ({value, row: {id, snapshotVersion}}) => value > 0 ? <span style={{color: 'green'}}>
        {value}
        <ModalReplayUsers offerReplayId={id} replayId={replayId} version={snapshotVersion}/>
      </span> : value,
    },
  ], []);

  return (
    <Box sx={{width: '100%', paddingTop: 2, textAlign: 'center'}}>
      {state === REPLAY_STATE_LOADING && <CircularProgress/>}

      {state === REPLAY_STATE_ERROR && <Alert severity="error">
        Impossible de récupérer les informations de ce replay.
        Veuillez vérifier que vous êtes bien connecté.
        <br/>
        <Button
          onClick={loadReplay}
          sx={{marginTop: 2}}
          variant="contained"
        >Recommencer</Button>
      </Alert>}

      {state === REPLAY_STATE_LOADED && <div style={{textAlign: 'left'}}>
        <Typography
          sx={{marginBottom: 2, textAlign: 'center'}}
          variant="h4"
        >
          <Button
            onClick={() => window.history.back(-1)}
            sx={{float: 'left', marginBottom: 1}}
            variant="outlined"
          >
            <KeyboardArrowLeftIcon sx={{marginRight: 1}}/> Précedent
          </Button>
          <Menu
            anchorEl={buttonActionRef.current}
            open={showActionMenu}
            onClose={() => setShowActionMenu(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={() => {
                setShowActionMenu(false)
                loadReplay();
              }}
            >
              <ListItemIcon><CachedIcon fontSize="small"/></ListItemIcon>
              <ListItemText>Actualiser</ListItemText>
            </MenuItem>
            <Tooltip
              title="Permet de récupérer le fichier brut MP4. A n'utiliser uniqument qu'en cas de gros problèmes / urgences."
              placement="left"
              arrow
            >
              <MenuItem
                onClick={() => {
                  setShowActionMenu(false);
                  downloadFile(data.replay.raw_file, `Live du ${liveAt}.mp4`)
                }}
                disabled={!data.replay.raw_file}
              >
                <ListItemIcon><DownloadIcon fontSize="small"/></ListItemIcon>
                <ListItemText>Télécharger la vidéo brut</ListItemText>
              </MenuItem>
            </Tooltip>
            <MenuItem
              onClick={() => navigate(`/offers/${encodeURIComponent(`/offers/${data.offer.uuid}`)}/show`)}
            >
              <ListItemIcon><BallotIcon fontSize="small"/></ListItemIcon>
              <ListItemText>Voir l'offre (Backoffice)</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => window.open(`https://www.wooskill.com/offer/${data.offer.slug}`)}>
              <ListItemIcon><BallotIcon fontSize="small"/></ListItemIcon>
              <ListItemText>Voir l&apos;offre (Wooskill.com)</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => navigate(`/#/users/${encodeURIComponent(`/users/${data.skiller.uuid}`)}/show`)}
            >
              <ListItemIcon><PersonIcon fontSize="small"/></ListItemIcon>
              <ListItemText>Voir le skiller</ListItemText>
            </MenuItem>
            {!data.replay.skillerWantRecord && <Tooltip
              title="Rendre disponible au skiller pour qu'il puisse le publier si il le souhaite dans son espace Replay."
              placement="left"
              arrow
            >
              <MenuItem onClick={() => {
                setShowActionMenu(false);
                doActivateReplay();
              }}>
                <ListItemIcon><DvrIcon fontSize="small"/></ListItemIcon>
                <ListItemText>Rendre disponible au skiller</ListItemText>
              </MenuItem>
            </Tooltip>}
          </Menu>

          <Button
            onClick={() => setShowActionMenu(true)}
            ref={buttonActionRef}
            sx={{float: 'right', marginBottom: 1}}
            variant="outlined"
          >
            Menu
            <ArrowDropDownIcon sx={{marginLeft: 1}}/>
          </Button>

          <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 5, alignItems: 'center'}}>
            <ReplayStatus
              status={data.status}
              version={data.replay.version}
              skillerWantRecord={data.replay.skillerWantRecord}
            />
          </div>
        </Typography>

        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs
            value={recordTabIndex}
            onChange={(event, newValue) => setRecordTabIndex(newValue)}
          >
            <Tab label="Le Replay"/>
            <Tab label={`Versions publiés (${(data.versions || []).length})`}/>
            <Tab label="Informations techniques"/>
          </Tabs>
        </Box>

        {recordTabIndex === INDEX_REPLAY && <Card>
          <CardActionArea component="div" sx={{cursor: 'default'}} disableRipple>
            <CardMedia
              sx={{
                alignItems: 'center',
                aspectRatio: '16 / 9',
                background: 'black',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                maxWidth: 'min(calc(100vw - 270px), 1000px)',
              }}
            >
              {!!data?.record?.src && <video
                className="video-js"
                crossOrigin="anonymous"
                style={{width: '100%', height: '100%', background: 'black'}}
                ref={videoRef}
                playsInline
              />}

              {!data?.record?.src && <div>La vidéo n&apos;est pas disponible pour le moment.</div>}
            </CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Offre : {data.offer.title}
              </Typography>
              <Typography variant="body3" color="text.secondary">
                Skiller : {data.skiller.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Replay du live du {liveAt}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>}


        {recordTabIndex === INDEX_VERSIONS && <> <Card variant="outlined" sx={{mt: 3}}>
          <CardContent>
            <DataGrid
              columns={versionGridColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 100,
                  },
                },
              }}
              rows={data.versions || []}
              disableColumnFilter
              disableColumnMenu
              hideFooter
            />
            <Typography variant="body" component="div" sx={{mt: 2}}>
              La version la plus élevé correspond à celle utilisé pour les nouvelles acquisition de la vidéo.
              A chaque fois que le Skiller modifie un élément dans son offre de Replay, une nouvelle version est créé.
              Les clients resent figé sur la version acquise au moment T.
            </Typography>
          </CardContent>
        </Card>
        </>}

        {recordTabIndex === INDEX_TECHNICAL && <> <Card variant="outlined" sx={{mt: 3}}>
          <CardContent>
            <Typography variant="h5" component="div">
              Etapes techniques de conversions
            </Typography>
            <DataGrid
              columns={technicalGridColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 50,
                  },
                },
              }}
              rows={data.taskList}
              disableColumnFilter
              disableColumnMenu
              hideFooter
            />
          </CardContent>
        </Card>

          <Card variant="outlined" sx={{mt: 3}}>
            <CardContent>
              <Typography variant="h5" component="div">
                Console AWS
              </Typography>
              <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                Technique uniquement
              </Typography>
              <Typography sx={{mb: 1.5}} color="text.secondary">
                {!!data.replay.console_raw_file && <>
                  <Tooltip
                    title="Fichier brut de la vidéo sans le logo Wooskill sur le Bucket S3. Accessible que par la technique uniquement"
                    placement="bottom"
                    arrow
                  >
                    <Button
                      onClick={() => window.open(data.replay.console_raw_file)}
                      sx={{marginTop: 2}}
                      variant="outlined"
                    >
                      Bucket S3 du fichier brut
                    </Button>
                  </Tooltip>
                  <br/>
                </>}
                {!!data.replay.console_optimized_files && <>
                  <Tooltip
                    title="Dossier S3 contenant l'ensemble des fichiers optimisés (HSL, Miniature...). Accessible que par la technique uniquement"
                    placement="bottom"
                    arrow
                  >
                    <Button
                      onClick={() => window.open(data.replay.console_optimized_files)}
                      sx={{marginTop: 2}}
                      variant="outlined"
                    >
                      Bucket S3 des fichiers optimisés
                    </Button>
                  </Tooltip>
                  <br/>
                </>}

                <Tooltip
                  title="Fichier temporaire lors du traitement. Ce bucket peut être vide et ne pas contenir de fichier car il est automatiquement éffacé au bout d'un certain temps. Accessible uniquement par la technique."
                  placement="bottom"
                  arrow
                >
                  <Button
                    onClick={() => window.open(data.replay.console_live_temporary_directory)}
                    sx={{marginTop: 2}}
                    variant="outlined"
                  >
                    Bucket S3 temporaire
                  </Button>
                </Tooltip>
              </Typography>
            </CardContent>
          </Card>
        </>}
      </div>}
    </Box>
  );
};

export default Replay;
