import React, { useState } from 'react';
import { InputGuesser, EditGuesser } from "@api-platform/admin";
import {
  Button,
  CheckboxGroupInput,
  Loading,
  SelectInput,
  TextInput,
} from 'react-admin';
import { sexeChoices, rolesChoicesUser } from '../utils';
import Modal from '../../components/modal/index';
import './style/EditUser.css';
import { useParams } from 'react-router-dom';
import splitId from '../../utils/splitId';
import { resetUserPassword } from '../../routeApi/cognito';
import ModalResetPassword from './components/modalResetPassword';

const EditTitle = ({ record }) => {

  return (
    <span>
      Editer un utilisateur -{' '}
      {record ? `${record.lastname} ${record.firstname}` : ''}
    </span>
  );
};

const EditUsers = (props) => {
  const [showResetPassModal, setShowResetPassModal] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id: userUuid } = useParams();

  const resetPassword = (event, userUuid) => {
    event.preventDefault();
    setLoading(true);
    resetUserPassword(userUuid)
      .then(() => {
        setLoading(false);
        setShowResetPassModal(false);
      }).catch((error) => {
        setLoading(false);
        setError('Une erreur est survenue lors de la réinitialisation du mot de passe');
      });
  };

  return (
    <EditGuesser undoable={false} {...props} title={<EditTitle/>}>
      <InputGuesser source={'firstname'}/>
      <InputGuesser source={'lastname'}/>
      <InputGuesser source={'email'}/>
      <SelectInput source="gender" choices={sexeChoices}/>

      <InputGuesser source={'birth_date'}/>
      <InputGuesser source={'phone_country_code'}/>
      <InputGuesser source={'phone_number'}/>

      <TextInput source="deleted_at"/>

      <InputGuesser source={'enabled'}/>

      <CheckboxGroupInput source="roles" choices={rolesChoicesUser}/>
      <Button
        variant="contained"
        onClick={() => setShowResetPassModal(true)}
      >
        Générer un nouveau mot de passe utilisateur
      </Button>
      <div className={'message-alert'}>
        Attention, cette action est irréversible !
      </div>
      {showResetPassModal && <Modal onClose={() => setShowResetPassModal(false)}>
        {!loading && <ModalResetPassword
          closeModal={() => setShowResetPassModal(false)}
          handleResetPassword={(event) => resetPassword(event, splitId(userUuid))}
         />}
        {error && <div className={'error-message'}>{error}</div>}
        {loading && <Loading/>}
      </Modal>}

      <Button
        variant="contained"
        onClick={() => document.location.href = `/v2/userPolicies.html?userUuid=${splitId(userUuid)}`}
      >
        Gérer les droits utilisateur
      </Button>

    </EditGuesser>
  )
};

export default EditUsers;
