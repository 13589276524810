export const typesAvailabilities = {
  video: 'En visioconférence',
  f2f_client_location: 'À votre domicile',
  f2f_professional_location: 'Chez le Skiller',
};

export const statusAppointment = {
  booked: 'A venir',
  canceled: 'Annulé',
  created: 'Créé',
  done: 'Terminé',
};

export const typeOffer = {
  individual: 'Individuelle',
  masterclass: 'Masterclass',
  collectif: 'Collective',
  service: 'Service',
  coaching: 'Coaching'
};
