import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from "react";
import WSExportButton from "./WSExportButton.jsx";
import { getDataToExport } from "../../../routeApi/exports";
import { DateTimePicker } from "@mui/x-date-pickers";
import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import * as moment from "moment/moment.js";

const CollectiveOfferAvailabilityExport = ({ offer }) => {
  const [startDate, setStartDate] = useState(dayjs().add(1, 'hours'));
  const [endDate, setEndDate] = useState(dayjs().add(1, 'days'));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);


  const uploadData = () => {
    setIsLoading(true);
    const timestampNow = dayjs().unix();
    const timestampStartDate = startDate.unix();
    const timestampEndDate = endDate.unix();

    if (timestampStartDate <= timestampNow) {
      setError("La date de début doit être postérieure à la date actuelle");
      return;
    }
    if (timestampStartDate > timestampEndDate) {
      setError("La date de début doit être antérieure à la date de fin");
      return;
    }

    if (error) {
      setError(false);
    }

    getDataToExport(
      'availableCollectivesOffers',
      {
        startDate: timestampStartDate,
        endDate: timestampEndDate
      }).then((response) => {
      if (response.code_error) {
        setError('une erreur est survenue');
        return;
      }
      const csv = convertToCSV({
        data: response,
      });

      downloadCSV(
        csv,
        `offres_disponibles_${startDate.format('DDMMYYYYHmm')}_${endDate.format('DDMMYYYYHmm')}`
      );
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  return (<>
      <h2>Offres collectives disponibles</h2>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
        <DemoContainer components={['DatePicker', 'DatePicker']}>
          <DateTimePicker
            label="Date de début"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
          />
          <DateTimePicker
            label="Date de fin"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
          />
        </DemoContainer>
      </LocalizationProvider>
      <WSExportButton
        label={'Télécharger'}
        error={error}
        isLoading={isLoading}
        uploadData={uploadData}
      />
    </>
  )
}

export default CollectiveOfferAvailabilityExport;
