import {getAuthorizationHeader} from '../../utils/CognitoHelpers';

const generateHeaders = async (extraHeaders = {}) => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  ...(await getAuthorizationHeader()),
  ...(extraHeaders || {}),
})

const createGetOptions = async (extraHeaders = {}) => ({
  method: 'GET',
  headers: await generateHeaders(extraHeaders),
});

const createPostOptions = async (extraHeaders = {}) => ({
  method: 'POST',
  headers: await generateHeaders(extraHeaders),
});

const checkResponse = (response) => {
  if (response.status < 300 && response.status >= 200) {
    return response.json();
  }
  return Promise.reject(response);
};

export const getRelaysList = async (page) => fetch(
  `${process.env.REACT_APP_ENTRYPOINT}/admin/replays?page=${page}`,
  await createGetOptions()
).then(checkResponse);

export const getRelaysInformation = async (replayId) => fetch(
  `${process.env.REACT_APP_ENTRYPOINT}/admin/replays/${encodeURIComponent(replayId)}`,
  await createGetOptions()
).then(checkResponse);

export const activateReplay = async (replayId) => fetch(
  `${process.env.REACT_APP_ENTRYPOINT}/admin/replays/${encodeURIComponent(replayId)}/activate`,
  await createPostOptions()
).then(checkResponse);

export const getRelaysUsers = async (replayId, offerReplayId) => fetch(
  `${process.env.REACT_APP_ENTRYPOINT}/admin/replays/${encodeURIComponent(replayId)}/offers/${encodeURIComponent(offerReplayId)}/users`,
  await createGetOptions()
).then(checkResponse);

export const getOfferRelaysList = async (offerId) => fetch(
  `${process.env.REACT_APP_ENTRYPOINT}/admin/offers/${encodeURIComponent(offerId)}/replays`,
  await createGetOptions()
).then(checkResponse);

export const getUserRelaysList = async (userId, type) => fetch(
  `${process.env.REACT_APP_ENTRYPOINT}/admin/users/${encodeURIComponent(userId)}/replays/${type}`,
  await createGetOptions()
).then(checkResponse);
