import React, { useEffect, useState } from 'react';
import { fetchSponsoredPartiesFromSponsor } from "../../routeApi/profiles";
import { SimpleShowLayout } from 'react-admin';

const SponsoredPartiesShow = ({ id }) => {
  const [sponsoredParties, setSponsoredParties] = useState([]);

  useEffect(() => {
    fetchSponsoredPartiesFromSponsor(id.split('/')[2])
      .then(data => {
        setSponsoredParties(data);
      }).catch(error => {
        setSponsoredParties(["error"]);
    });
  }, [id]);

  if (sponsoredParties === null || sponsoredParties === []) {
    return <div>Loading...</div>;
  }

  if (sponsoredParties === ["error"]) {
    return <div>Impossible de récupérer les données</div>;
  }

  return (<SimpleShowLayout>
      {sponsoredParties.map(party => (
        <>
          <div key={party.name} className={"party-item"}>
            <p>nom: {party.name}</p>
            <p>prénom: {party.firstname}</p>
            <p>email: {party.email}</p>
          </div>
          <div className={"separator"}/>
        </>
      ))}
    </SimpleShowLayout>
  );
};

export default SponsoredPartiesShow;
