module.exports = {
  ra: {
    action: {
      add_filter: 'Ajouter un filtre',
      add: 'Ajouter',
      back: 'Retour',
      bulk_actions:
        '%{smart_count} selectionné |||| %{smart_count} selectionnés',
      cancel: 'Annuler',
      clear_input_value: 'Vider le champ',
      clone: 'Dupliquer',
      confirm: 'Confirmer',
      unselect: 'Déselectionner',
      create: 'Créer',
      delete: 'Supprimer',
      edit: '',
      export: 'Exporter',
      list: 'Liste',
      refresh: 'Actualiser',
      remove_filter: 'Supprimer ce filtre',
      remove: 'Supprimer',
      save: 'Enregistrer',
      search: 'Rechercher',
      show: '',
      sort: 'Trier',
      undo: 'Annuler',
      expand: 'Étendre',
      close: 'Fermer',
      filterby: 'Filtrer par',
    },
    boolean: {
      true: 'Oui',
      false: 'Non',
      null: '',
    },
    configurable: {
      customize: 'Personnaliser',
    },
    page: {
      create: 'Créer %{name}',
      dashboard: 'Tableau de bord',
      edit: '%{name} #%{id}',
      error: 'Un problème est survenu',
      list: '%{name}',
      loading: 'Chargement',
      not_found: 'Page manquante',
      show: '%{name} #%{id}',
      empty: 'Pas encore de %{name}.',
      invite: 'Voulez-vous en créer un ?',
    },
    input: {
      file: {
        upload_several:
          'Déposez les fichiers à uploader, ou cliquez pour en sélectionner.',
        upload_single:
          'Déposez le fichier à uploader, ou cliquez pour le sélectionner.',
      },
      image: {
        upload_several:
          'Déposez les images à uploader, ou cliquez pour en sélectionner.',
        upload_single:
          "Déposez l'image à uploader, ou cliquez pour la sélectionner.",
      },
      references: {
        all_missing: 'Impossible de trouver des données de références.',
        many_missing:
          'Au moins une des références associées semble ne plus être disponible.',
        single_missing: 'La référence associée ne semble plus disponible.',
      },
      password: {
        toggle_visible: 'Cacher le mot de passe',
        toggle_hidden: 'Montrer le mot de passe',
      },
    },
    message: {
      about: 'Au sujet de',
      are_you_sure: 'Êtes-vous sûr ?',
      bulk_delete_content:
        'Êtes-vous sûr(e) de vouloir supprimer cet élément ? |||| Êtes-vous sûr(e) de vouloir supprimer ces %{smart_count} éléments ?',
      bulk_delete_title:
        'Supprimer %{name} |||| Supprimer %{smart_count} %{name}',
      delete_content: 'Êtes-vous sûr(e) de vouloir supprimer cet élément ?',
      delete_title: 'Supprimer %{name} #%{id}',
      details: 'Détails',
      error:
        "En raison d'une erreur côté navigateur, votre requête n'a pas pu aboutir.",
      invalid_form: "Le formulaire n'est pas valide.",
      loading:
        'La page est en cours de chargement, merci de bien vouloir patienter.',
      no: 'Non',
      not_found:
        "L'URL saisie est incorrecte, ou vous avez suivi un mauvais lien.",
      yes: 'Oui',
    },
    navigation: {
      no_results: 'Aucun résultat',
      no_more_results:
        'La page numéro %{page} est en dehors des limites. Essayez la page précédente.',
      page_out_of_boundaries: 'La page %{page} est en dehors des limites',
      page_out_from_end: 'Fin de la pagination',
      page_out_from_begin: 'La page doit être supérieure à 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd} sur %{total}',
      page_rows_per_page: 'Lignes par page :',
      next: 'Suivant',
      prev: 'Précédent',
      skip_nav: 'Skip nav'
    },
    auth: {
      auth_check_error: 'Merci de vous connecter pour continuer',
      user_menu: 'Profil',
      username: 'Adresse e-mail',
      password: 'Mot de passe',
      sign_in: 'Connexion',
      sign_in_error: "Échec de l'authentification, merci de réessayer",
      logout: 'Déconnexion',
      login_error: 'Identifiants invalides',
    },
    notification: {
      updated: 'Élément mis à jour |||| %{smart_count} élements mis à jour',
      created: 'Élément créé',
      deleted: 'Élément supprimé |||| %{smart_count} élements supprimés',
      bad_item: 'Élément inconnu',
      item_doesnt_exist: "L'élément n'existe pas",
      http_error: 'Erreur de communication avec le serveur',
      data_provider_error:
        'Erreur dans le dataProvider. Plus de détails dans la console.',
      i18n_error:
        'Erreur de chargement des traductions pour la langue sélectionnée',
      canceled: 'Action annulée',
      logged_out: 'Votre session a pris fin, veuillez vous reconnecter.',
    },
    validation: {
      required: 'Ce champ est requis',
      minLength: 'Minimum %{min} caractères',
      maxLength: 'Maximum %{max} caractères',
      minValue: 'Valeur minimum requise %{min}',
      maxValue: 'Valeur maximum autorisée %{max}',
      number: 'Doit être un nombre',
      email: 'Doit être un email',
      oneOf: 'Doit être au choix: %{options}',
      regex: 'Doit respecter un format spécifique (regexp): %{pattern}',
      helper_roles: 'Veuillez sélectionner 1 rôle.',
      helper_password:
        ' Le mot de passe doit contenir :\n' +
        '        Taille : 6 charactères,\n' +
        '        1 chiffre ou 1 charactere spe,\n' +
        '        1 lettre majuscule,\n' +
        '        1 lettre minuscule,\n' +
        '      ',
    },
  },
  resources: {
    'admin/administrators': {
      fields: {
        firstname: 'Prénom',
        lastname: 'Nom',
        email: 'E-mail',
        phone_number: 'Numéro de téléphone',
        phone_country_code: 'Indicatif du numéro de téléphone',
        created_at: 'Date de création',
        updated_at: 'Date de modification',
        deleted_at: 'Date de suppression',
        last_logon: 'Dernière connexion',
        roles: 'Rôle',
        password: 'Mot de passe',
        phone_verification_code: 'Code de vérification du téléphone',
        enabled: 'Activé',
      },
    },
    users: {
      fields: {
        firstname: 'Prénom',
        verified_profile: 'Vérifier le profil',
        highlighted: 'Mettre ce profil vedette',
        enabled: 'Rendre le compte actif (Hors suppression)',
        lastname: 'Nom',
        email: 'E-mail',
        phone_number: 'Numéro de téléphone',
        phone_country_code: 'Indicatif du numéro de téléphone',
        password: 'Mot de passe',
        gender: 'Sexe',
        birth_date: "Date d'anniversaire",
        created_at: 'Date de création',
        deleted_at: 'Date de suppression',
        last_logon: 'Dernière connexion',
      },
    },
    categories: {
      fields: {
        name: 'Nom',
        description: 'Description',
        enabled: 'Activé',
      },
    },
    domains: {
      fields: {
        name: 'Nom',
        enabled: 'Activé',
      },
    },
    specialities: {
      fields: {
        name: 'Nom',
        enabled: 'Activé',
      },
    },
    qualifications: {
      fields: {
        name: 'Nom',
        description: 'Description',
        enabled: 'Activé',
      },
    },
    tags: {
      fields: {
        name: 'Nom',
      },
    },
    html_contents: {
      fields: {
        created_at: 'Date de création',
        updated_at: 'Date de modification',
      },
    },
    campaigns: {
      fields: {
        first_name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        job_name: 'Profession',
        tag: 'Compétences',
        website_pro: 'Site internet',
        created_at: 'Date de création',
      },
    },
    promotions: {
      fields: {
        title: 'Titre',
        count_code_to_add: 'Nombre de code',
        count_code_to_generate: 'Nombre de code généré',
        count_code_used: 'Nombre de codes utilisés',
        value: 'Valeur',
        used_limit: "Limite d'utilisation",
        limit_date: 'Date limite',
        professional: 'Professionnel',
        offer: 'Offre',
        created_at: 'Date de création',
        updated_at: 'Date de modification',
      },
    },
  },
};
