import React from 'react';
import { FieldGuesser, ShowGuesser } from '@api-platform/admin';

const EditTitle = ({ record }) => <span>Editer un tag - {record && `${record.name}`}</span>;

const ShowSpeciality = (({ classes, ...props }) => (
  <ShowGuesser undoable={false} {...props} title={<EditTitle />}>
    <FieldGuesser source={'name'} label="Nom" addLabel={true} />
    <FieldGuesser source={'enabled'} addLabel={true} />
  </ShowGuesser>
));

export default ShowSpeciality;
