import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, CircularProgress, Paper } from '@mui/material';
import { postShareLocalStorage } from '../../api/users/api';

export const GoToWebsite = () => {
  const [error, setError] = useState(false);

  useEffect(() => {
    const localStorageToTransfer = Object.entries(localStorage).reduce((result, [key, value]) => {
      if (key.startsWith(`CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}`)) {
        result[key] = value;
      }
      return result;
    }, {});

    postShareLocalStorage(localStorageToTransfer).then(({ administratorUuid }) => {
      const {protocol, host} = new URL(process.env.REACT_APP_LINK);
      document.location.href = `${protocol}//${host}/connect?administratorUuid=${encodeURIComponent(administratorUuid)}`;
    }).catch(error => {
      console.error(error);
      setError(true)
    });
  }, []);

  return <div>
    <Paper elevation={3}>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'light',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          marginTop: 5,
          padding: 2,
        }}
      >
        {!error && <><CircularProgress/> <br />Accès à Wooskill en cours...</>}
        {error && 'Erreur lors de l\'accès à Wooskill.'}
      </Box>
    </Paper>
  </div>;
}
