import {styled} from "@mui/material";

export const Container = styled('div')({
    display: 'flex',
    marginTop: 40
});

export const Text = styled('div')(({fontWeight, fontSize, align}) => ({
    fontSize: fontSize ? fontSize + 'em' : 14,
    fontWeight: fontWeight ? fontWeight : 'normal',
    textAlign: align ? align : 'inherit'
}));

export const BadgePieContainer = styled('div') ({
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 40,
    marginBottom: 40
});

export const PriceCategContainer = styled('div') ({
    padding: 15
});

export const PriceCategItem = styled('div')(({index}) => ({
    display: 'flex',
    flexDirection: 'row',
    background: index % 2 === 0 ? '#EDEBEB' : '#F7F7F7',
    margin: 2,
    borderRadius: 5
}));

export const A = styled('a') ({
    textDecoration: 'none',
});