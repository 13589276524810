import React from 'react';
import { AutocompleteInput, ReferenceInput, required} from 'react-admin';
import { InputGuesser, CreateGuesser } from "@api-platform/admin";

const CreateDifficulties = (({ classes, ...props }) => {

  const disableWheel = () => {
    document.activeElement.blur()
  }

  return (
    <CreateGuesser  undoable={true} {...props} title={'Créer une catégorie'}>
        <InputGuesser
          source="name"
          label="Nom"
          onWheel={() => disableWheel()}
          validate={[required()]}
        />
        <ReferenceInput
          label="Categorie"
          reference="categories"
          source="category"
          allowEmpty={false}
          filter={{ pagination: false }}
        >
          <AutocompleteInput optionText="name" validate={[required()]}/>
        </ReferenceInput>
    </CreateGuesser>
  );
});

export default CreateDifficulties;
