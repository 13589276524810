import React, { useEffect, useState } from 'react';
import { FunctionField } from 'react-admin';
import { FieldGuesser, ShowGuesser } from '@api-platform/admin';
import { fetchSpecialitiesByDomain } from '../../routeApi/specialities';

const ShowTitle = ({ record }) => {
  return <span>Domaines - {record ? `${record.name}` : ''}</span>;
};

const ShowDomain = (({ classes, ...props }) => {
  const [specialities, setSpecialities] = useState([]);

  useEffect(() => {
    fetchSpecialitiesByDomain(props?.id?.split('/')[2])
    .then(data => {
      const newArray = [];
      data['hydra:member']?.map(speciality => {
        newArray.push({
          '@id': speciality['@id'],
          name: speciality.name
        });
      })
      setSpecialities(newArray);
    })
    .catch(error => {
      console.log('EROR', error);
    });
  }, []);

  const Specialities = () => (
    <ul>
      {specialities?.map(item => (
        <li key={item?.name}>{item?.name}</li>
      ))}
    </ul>
  )

  Specialities.defaultProps = {
    addLabel: true
  };

  return (
    <ShowGuesser {...props} title={<ShowTitle />}>
      <FunctionField
        label="id"
        render={record => `${record?.id?.split('/')[2]}`}
      />
      <FieldGuesser source={'name'} label="Domaines" addLabel={true} />

      <Specialities label="Spécialités" />

      <FieldGuesser source={'enabled'} addLabel={true} />
    </ShowGuesser>
  );
});

export default ShowDomain;
