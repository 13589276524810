import React from 'react';
import { required } from 'react-admin';
import { InputGuesser, CreateGuesser } from "@api-platform/admin";

const CreateTags = (({ classes, ...props }) => (
  <CreateGuesser undoable={true} {...props} title={'Créer un tag'}>
    <InputGuesser source={'name'} validate={[required()]} />
  </CreateGuesser>
));

export default CreateTags;
