import { ListItemIcon, ListItemText, Menu } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { getAdminConnectedAs, postDisconnectAdminUser } from '../api/users/api';

const styles = {
  background: 'red',
  borderRadius: 5,
  cursor: 'pointer',
  fontSize: 12,
  padding: '2px 4px',
}

export const ConnectedAs = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [connectedAs, setConnectedAs] = useState(null);
  const ref = useRef();

  const refreshConnectedAs = useCallback(() => {
    getAdminConnectedAs().then((response) => {
      if (Object.keys(response).length === 0) {
        setConnectedAs(null);
        return;
      }
      setConnectedAs(response);
    }).catch(error => {
      console.error(error);
      setConnectedAs(null);
    });
  }, []);

  const doGoWooskill = useCallback(() => {
    setMenuOpen(false);
    window.open('/#/go-to-website', '_blank');
  }, [])

  const doLogout = useCallback(() => {
    setMenuOpen(false);
    postDisconnectAdminUser().then(() => {
      refreshConnectedAs();
    });
  }, []);

  useEffect(() => {
    refreshConnectedAs();
  }, []);

  if (!connectedAs) {
    return null;
  }

  return <>
    <div style={styles} ref={ref} onClick={() => setMenuOpen(true)}>
      <ArrowDropDownIcon sx={{ml: 2, float: 'right'}}/>
      Connecté sur Wooskill en tant que <br/><strong>{connectedAs.firstname} {connectedAs.lastname}</strong>
    </div>

    <Menu
      anchorEl={ref.current}
      open={menuOpen}
      onClose={() => setMenuOpen(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuItem onClick={() => doGoWooskill()}>
        <ListItemIcon>
          <OpenInNewIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Accéder à Wooskill ({connectedAs.firstname} {connectedAs.lastname})</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => doLogout()}>
        <ListItemIcon>
          <LockPersonIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Déconnecter {connectedAs.firstname} {connectedAs.lastname} Wooskill</ListItemText>
      </MenuItem>
    </Menu>
  </>
}
