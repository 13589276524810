import React from 'react';
import {
  FunctionField,
  TextField,
  Filter,
  TextInput,
  List,
  Datagrid
} from 'react-admin';
import { FieldGuesser, ListGuesser } from '@api-platform/admin';

const GridFilters = props => (
    <Filter {...props}>
      <TextInput label="Nom" source="name" name="nom" />
    </Filter>
);

const ListDifficulties = (({ classes, ...props }) => (
    <List
          filters={<GridFilters /> }
          title="Niveaux"
          {...props}
      >
      <Datagrid>
        <FunctionField label="id" render={record => `${record.id.split('/')[2]}`} />
        <TextField source={'name'} label="Nom" />
        <FunctionField
          label="Catégorie"
          render={record => record?.category?.name}
        />
      </Datagrid>
    </List>
));

export default ListDifficulties;