import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';
import {getRelaysList} from './api';
import {Alert, Button, Tooltip, Typography} from '@mui/material';
import {ReplayStatus} from './ReplayStatus';
import CachedIcon from '@mui/icons-material/Cached';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import moment from 'moment';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import BallotIcon from "@mui/icons-material/Ballot";
import {useNavigate, useSearchParams} from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';

const LIST_STATE_LOADING = 'loading';
const LIST_STATE_LOADED = 'loaded';
const LIST_STATE_ERROR = 'error';
const LIST_STATE_NOT_AUTHORIZED = 'not-authorized';

const Replays = () => {
  const [listState, setListState] = useState(LIST_STATE_LOADING);
  const [list, setList] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPage, setTotalPage] = useState(0);
  const page = useMemo(() => parseInt(searchParams.get('page') || 1), [searchParams]);
  const navigate = useNavigate();

  const columns = useMemo(() => [
    {
      field: 'id',
      headerName: 'Live Id',
      width: 250,
      sortable: false,
      renderCell: ({value}) => <span style={{fontSize: 10}}>{value}</span>
    },
    {
      field: 'liveAt',
      headerName: 'Live démarré à',
      width: 150,
      sortable: false,
      renderCell: ({value}) => moment(value).format('DD/MM/YYYY à HH[h]mm')
    },
    {
      field: 'skillerName',
      headerName: 'Skiller',
      width: 200,
      sortable: false,
      renderCell: ({value, row: {skillerUuid}}) => {
        return <>
          <PersonIcon
            fontSize="small"
            sx={{cursor: 'pointer', float: 'left', marginRight: 1}}
            onClick={() => navigate(`/users/${encodeURIComponent(`/users/${skillerUuid}`)}/show`)}
          />
          {value}
        </>
      }
    },
    {
      field: 'offerTitle',
      headerName: 'Titre de l\'offre',
      width: 300,
      flex: 1,
      sortable: false,
      renderCell: ({value, row: {offerUuid}}) => <>
        <Tooltip
          title="Afficher l'offre"
          placement="bottom"
          arrow
        >
          <BallotIcon
            fontSize="small"
            onClick={() => navigate(`/offers/${encodeURIComponent(`/offers/${offerUuid}`)}/show`)}
            sx={{
              cursor: 'pointer',
              float: 'left',
              marginRight: 1,
            }}
          />
        </Tooltip>
        <Tooltip
          placement="bottom"
          title={value}
          arrow
        >
          {value}
        </Tooltip>
      </>
    },
    {
      field: 'status',
      headerName: 'Etat',
      width: 220,
      sortable: false,
      renderCell: ({value, row: {lastVersion, skillerWantRecord}}) => <div
        style={{textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 5}}>
        <ReplayStatus
          status={value}
          version={lastVersion}
          skillerWantRecord={skillerWantRecord}
        />
      </div>
    },
    {
      headerName: 'Actions',
      width: 150,
      sortable: false,
      renderCell: ({id}) => <Button
        color="primary"
        href={`#/replays/${id}`}
        size="small"
        sx={{marginRight: 1}}
        variant="contained"
      >
        Afficher
        <KeyboardArrowRightIcon sx={{marginLeft: 1}}/>
      </Button>
    }
  ], [page]);

  const loadReplays = useCallback(() => {
    setListState(LIST_STATE_LOADING);
    getRelaysList(page).then(({list, pageSize, numberOfPages, isSuperAdmin}) => {
      if (!isSuperAdmin) {
        setListState(LIST_STATE_NOT_AUTHORIZED);
        return
      }
      setList(list);
      setPageSize(pageSize);
      setTotalPage(numberOfPages)
      setListState(LIST_STATE_LOADED);
    }).catch(error => {
      setListState(LIST_STATE_ERROR);
      console.error('Error', error)
    });
  }, [page])

  useEffect(() => {
    loadReplays();
  }, [page]);

  return (
    <Box sx={{height: 'calc(100% - 120px)', width: 'calc(100% - 20px)', paddingTop: 2}}>
      <Typography
        sx={{marginBottom: 2}}
        variant="h4"
      >
        Liste des Lives avec Replays
        <Button
          onClick={loadReplays}
          sx={{float: 'right'}}
          variant="outlined"
        >
          <CachedIcon sx={{marginRight: 1}}/> Actualiser
        </Button>
      </Typography>
      {listState === LIST_STATE_ERROR && <Alert severity="error">
        Impossible de récupérer la liste des Replays.
        Veuillez vérifier que vous êtes bien connecté.
        <br/>
        <Button
          onClick={loadReplays}
          sx={{marginTop: 2}}
          variant="contained"
        >Recommencer</Button>
      </Alert>}

      {listState === LIST_STATE_NOT_AUTHORIZED && <Alert severity="error">
        Vous n&apos;avez pas les droits d&apos;accès à cette liste.
      </Alert>}

      {listState === LIST_STATE_LOADED && !list.length && <Alert severity="info">Aucun replay disponible</Alert>}

      {((listState === LIST_STATE_LOADED && list.length > 0) || listState === LIST_STATE_LOADING) && <DataGrid
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize,
            },
          },
        }}
        loading={listState === LIST_STATE_LOADING}
        rows={list}
        disableColumnFilter
        disableColumnMenu
        hideFooter
      />}

      {listState === LIST_STATE_LOADED && <div style={{textAlign: 'center'}}>
        {page > 1 && <Button
          onClick={() => setSearchParams(current => ({...current, page: page - 1}))}
          sx={{float: 'left', marginTop: 1}}
          variant="outlined"
        >
          <KeyboardArrowLeftIcon sx={{marginRight: 1}}/> Précédent
        </Button>}
        {page < totalPage && <Button
          onClick={() => setSearchParams(current => ({...current, page: page + 1}))}
          sx={{float: 'right', marginTop: 1}}
          variant="outlined"
        >
          Suivant <KeyboardArrowRightIcon sx={{marginLeft: 1}}/>
        </Button>}
        {totalPage > 1 && <div style={{paddingTop: 15}}>Page {page} / {totalPage}</div>}
      </div>}
    </Box>
  );
};

export default Replays;
