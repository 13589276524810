import { fetchData } from '../overrides/Fetch';
import {getAuthorizationHeader} from '../utils/CognitoHelpers';

export const fetchProfilesByUuid = async uid => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}${uid}`;
  let headers;
  headers = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json',
      ...(await getAuthorizationHeader()),
    },
  };

  return fetchData(entrypoint, headers);
};

export const disableProfileByUuid = async uid => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}${uid}/disable`;
  let headers;

    headers = {
      method: 'PATCH',
      headers: {
        Accept: 'application/ld+json',
        'Content-Type': 'application/merge-patch+json',
        ...(await getAuthorizationHeader()),
      },
      body: JSON.stringify(fetchProfilesByUuid(uid))
    };

    return fetchData(entrypoint, headers);
};

export const enableProfileByUuid = async uid => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}${uid}/enable`;
  let headers;

    headers = {
      method: 'PATCH',
      headers: {
        Accept: 'application/ld+json',
        'Content-Type': 'application/merge-patch+json',
        ...(await getAuthorizationHeader()),
      },
      body: JSON.stringify(fetchProfilesByUuid(uid))
    };

    return fetchData(entrypoint, headers);
};

export const disableProfileIndexByUuid = async uid => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}${uid}/disable_index`;
  let headers;

    headers = {
      method: 'PATCH',
      headers: {
        Accept: 'application/ld+json',
        'Content-Type': 'application/merge-patch+json',
        ...(await getAuthorizationHeader()),
      },
      body: JSON.stringify(fetchProfilesByUuid(uid))
    };

    return fetchData(entrypoint, headers);
};

export const enableProfileIndexByUuid = async uid => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}${uid}/enable_index`;
  let headers;
    headers = {
      method: 'PATCH',
      headers: {
        Accept: 'application/ld+json',
        'Content-Type': 'application/merge-patch+json',
        ...(await getAuthorizationHeader()),
      },
      body: JSON.stringify(fetchProfilesByUuid(uid))
    };

    return fetchData(entrypoint, headers);
};

export const fetchSponsoredPartiesFromSponsor = async id => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/admin/${id}/sponsored-parties`;
  let headers;

  headers = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json',
      ...(await getAuthorizationHeader()),
    },
  };

  return fetchData(entrypoint, headers);
}
