import React from 'react';

import { BadgeContainer, Text } from './Badge.styled';

export default function Badge({title, number, colorPrimary, colorSecondary}) {
    return ( 
        <BadgeContainer colorPrimary={colorPrimary} colorSecondary={colorSecondary}>
            <Text fontSize={2} fontWeight={700}>{number}</Text>
            <Text>Nouveaux</Text>
            <Text fontSize={1} fontWeight={500}>{title}</Text>
        </BadgeContainer>
    )
}
