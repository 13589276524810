import { getAuthorizationHeader } from "../utils/CognitoHelpers";
import { fetchData } from "../overrides/Fetch";
import { queryParamsToString } from "../utils/QueryParamsConverter.js";

export const getDataToExport = async (dataName, query) => {
  const entrypoint = query ?
    `${process.env.REACT_APP_ENTRYPOINT}/admin/export/${dataName}${queryParamsToString(query)}` :
    `${process.env.REACT_APP_ENTRYPOINT}/admin/export/${dataName}`;
  const header = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json',
      ...(await getAuthorizationHeader()),
    },
  };


  return fetchData(entrypoint, header);
}
