import React from 'react';
import { FunctionField, BulkDeleteButton, BulkExportButton } from 'react-admin';
import { FieldGuesser, ListGuesser } from '@api-platform/admin';


import { getLocales } from '../../providers/i18nProvider';

const PostBulkActionButtons = props => (
  <>
    <BulkDeleteButton {...props} undoable={false} />
    <BulkExportButton {...props} />
  </>
);

const ListAdmin = (({ classes, ...props }) => (
  <ListGuesser
    {...props}
    title="Les Administrateurs"
    bulkActionButtons={<PostBulkActionButtons />}>
    <FunctionField label="id" render={record => `${record.id.split('/')[3]}`} />
    <FieldGuesser source={'firstname'} />
    <FieldGuesser source={'lastname'} />
    <FieldGuesser source={'email'} />
    <FunctionField source="roles" render={record => `${record.roles[0]}`} />
    <FunctionField
      addLabel={false}
      label="Suppression"
      source="deleted_at"
      locales={getLocales}
      style={{
        color: 'red',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      }}
      render={record => (record.deleted_at !== null ? `Supprimé` : '')}
    />
    <FieldGuesser source={'enabled'} />
  </ListGuesser>
));

export default ListAdmin;
