import { AppBar, TitlePortal, ToggleThemeButton } from 'react-admin';
import Logo from './Logo';
import { Box } from "@mui/material";
import { ConnectedAs } from './ConnectedAs';

const CustomAppBar = ({classes, ...props}) => {
  return <AppBar
    {...props}
    toolbar={<>
      <ToggleThemeButton/>
    </>}
  >
    <TitlePortal/>
    <Logo/>
    <Box flex="1"/>
    <ConnectedAs />
  </AppBar>
};

export default CustomAppBar;
