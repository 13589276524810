// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.modal-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #282c34;
  max-width: 600px;
  padding: 20px;
  width: 100%;
}

.close-button {
  color: black;
  cursor: pointer;
  display: flex;
  font-size: 30px;
  height: 30px;
  justify-content: flex-end;
  position: relative;
  top: -20px;
}

`, "",{"version":3,"sources":["webpack://./src/components/modal/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,OAAO;EACP,eAAe;EACf,MAAM;EACN,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,uCAAuC;EACvC,cAAc;EACd,gBAAgB;EAChB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,eAAe;EACf,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,UAAU;AACZ","sourcesContent":[".modal-overlay {\n  align-items: center;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  left: 0;\n  position: fixed;\n  top: 0;\n  width: 100%;\n}\n\n.modal-container {\n  background: white;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\n  color: #282c34;\n  max-width: 600px;\n  padding: 20px;\n  width: 100%;\n}\n\n.close-button {\n  color: black;\n  cursor: pointer;\n  display: flex;\n  font-size: 30px;\n  height: 30px;\n  justify-content: flex-end;\n  position: relative;\n  top: -20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
