import React from 'react';
import { required, SelectArrayInput } from 'react-admin';
import { InputGuesser, EditGuesser } from "@api-platform/admin";

import { rolesChoices } from '../utils';

const EditTitle = ({ record }) => {
  return (
    <span>
      Editer - {record ? `${record.lastname} ${record.firstname}` : ''}
    </span>
  );
};

const EditAdmin = (({ classes, ...props }) => (
  <EditGuesser undoable={false} {...props} title={<EditTitle />}>
    <InputGuesser source={'firstname'} />
    <InputGuesser source={'lastname'} />
    <InputGuesser source={'email'} />

    <InputGuesser source={'phone_country_code'} />
    <InputGuesser source={'phone_number'} />
    <InputGuesser source={'enabled'} />
    <SelectArrayInput
      source="roles"
      choices={rolesChoices}
      validate={[required()]}
      helperText="ra.validation.helper_roles"
    />

    <InputGuesser source={'enabled'} />
  </EditGuesser>
));

export default EditAdmin;
