// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.party-item {
    padding: 10px;
    margin-bottom: 10px;
}

.separator {
    background: black;
    height: 3px;
    width: 100%;
}

.profileEditForm {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 50px;
    max-width: 500px;

    button {
        margin-top: 20px;
        width: 200px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/api/profiles/profile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,mBAAmB;IACnB,gBAAgB;;IAEhB;QACI,gBAAgB;QAChB,YAAY;IAChB;AACJ","sourcesContent":[".party-item {\n    padding: 10px;\n    margin-bottom: 10px;\n}\n\n.separator {\n    background: black;\n    height: 3px;\n    width: 100%;\n}\n\n.profileEditForm {\n    display: flex;\n    flex-direction: column;\n    padding: 0 20px;\n    margin-bottom: 50px;\n    max-width: 500px;\n\n    button {\n        margin-top: 20px;\n        width: 200px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
