import React, { cloneElement } from 'react';
import {
  BulkExportButton,
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  Filter,
  sanitizeListRestProps, ShowButton,
  TextInput,
  TopToolbar,
  useListContext,
  List,
  NumberField,
  TextField,
  BooleanField
} from 'react-admin';
import { ImportButtonCategorie } from '../../overrides/importCSV';

const ListActions = props => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ImportButtonCategorie {...props} />
      <CreateButton resource={resource} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </TopToolbar>
  );
};

const PostBulkActionButtons = props => (
  <>
    <BulkExportButton {...props} />
  </>
);

const GridFilters = props => (
  <Filter {...props}>
    <TextInput label="Nom de la catégorie" source="name" />
  </Filter>
);




const ListCategories = (({ classes, ...props }) => {
  return (
    <List
      {...props}
      title="Domaines"
      >
      <Datagrid>
        <NumberField
          label="id"
          source={`db_id`}
        />
        <TextField
          label={`Domaines`}
          source={'name'}
        />
        <BooleanField source={'enabled'} />
        <>
          <EditButton />
          <ShowButton />
        </>
      </Datagrid>
    </List>
  );
});

const ShowSubcategories = ({ id, record, source }) => {
  return(
    <ListCategories
      filter={{ categories: record.originId }}
      resource="domains"
      perPage={5}
      title="Domaines"
      exporter={false}
    />
)};


const ListCategory = (({ classes, ...props }) => {
  return (
    <List
      {...props}
      actions={<ListActions />}
      filters={<GridFilters />}
      bulkActionButtons={<PostBulkActionButtons />}
      expand={<ShowSubcategories  />}>
      <Datagrid>
        <NumberField
          label="id"
          source={`db_id`}
        />
        <TextField
          label={`Catégories`}
          source={'name'}
        />
        <BooleanField source={'enabled'} />
        <>
          <EditButton />
          <ShowButton />
        </>
      </Datagrid>
    </List>
  );
});



export default ListCategory;
