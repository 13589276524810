import { fetchData } from '../overrides/Fetch';
import { fetchProfilesByUuid } from "./profiles";
import { getAuthorizationHeader } from '../utils/CognitoHelpers';

export const fetchOffers = async (uid, jwtToken) => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}${uid}/offers`;
  let headers;

  headers = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json',
      ...(await getAuthorizationHeader()),
    },
  };

  return fetchData(entrypoint, headers);
};

export const fetchOffersByUuid = async (uid, jwtToken) => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/offers/${uid}`;
  let headers;

  headers = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json',
      ...(await getAuthorizationHeader()),
    },
  };

  return fetchData(entrypoint, headers);
};

export const patchOffersByUuid = async payload => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/offers/${payload.id}`;
  let headers;

  headers = {
    method: 'PATCH',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json',
      ...(await getAuthorizationHeader()),
    },
    body: JSON.stringify(payload)
  };

  return fetch(entrypoint, headers);
};

export const maskOfferByUuid = async (payload, jwtToken) => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/offers/${payload.id}/mask`;
  let headers;

  headers = {
    method: 'PATCH',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json',
      ...(await getAuthorizationHeader()),
    },
    body: JSON.stringify(payload, jwtToken)
  };

  return fetchData(entrypoint, headers);
}

// TODO SAME THAN PROFILE
export const disableOfferByUuid = async uid => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}${uid}/disable`;
  let headers;

  headers = {
    method: 'PATCH',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/merge-patch+json',
      ...(await getAuthorizationHeader()),
    },
    body: JSON.stringify(fetchProfilesByUuid(uid))
  };

  return fetchData(entrypoint, headers);
};

export const enableOfferByUuid = async uid => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}${uid}/enable`;
  let headers;

  headers = {
    method: 'PATCH',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/merge-patch+json',
      ...(await getAuthorizationHeader()),
    },
    body: JSON.stringify(fetchProfilesByUuid(uid))
  };

  return fetchData(entrypoint, headers);
};

export const disableOfferIndexByUuid = async (uid, jwtToken) => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/offers/${uid}/disable_index`;
  let headers;

  headers = {
    method: 'PATCH',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/merge-patch+json',
      ...(await getAuthorizationHeader()),
    },
    body: JSON.stringify(fetchOffersByUuid(uid, jwtToken))
  };

  return fetchData(entrypoint, headers);
};

export const enableOfferIndexByUuid = async (uid, jwtToken) => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/offers/${uid}/enable_index`;
  let headers;

  headers = {
    method: 'PATCH',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/merge-patch+json',
      ...(await getAuthorizationHeader()),
    },
    body: JSON.stringify(fetchOffersByUuid(uid, jwtToken))
  };

  return fetchData(entrypoint, headers);
};

export const saveFooterOffers = async (selectedIds) => {
  const offerUuids = selectedIds.map(id => id.replace('/offers/', ''));
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/offers/footer`;
  let headers;

  headers = {
    method: 'POST',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json',
      ...(await getAuthorizationHeader()),
    },
    body: JSON.stringify({ "offer_uuids": offerUuids }),
  };

  return fetchData(entrypoint, headers);
};
