import frenchMessages from '../translations/fr/index';
import polyglotI18nProvider from 'ra-i18n-polyglot';

const messages = {
  fr: frenchMessages,
};

export const getLocales = 'fr-FR';

export const i18nProvider = polyglotI18nProvider(locale => messages['fr']);
