import { styled } from "@mui/material";

export const BadgeContainer = styled('div')(({colorPrimary, colorSecondary}) => ({
    background: 'linear-gradient(45deg, ' + colorPrimary + ',' + colorSecondary + ')',
    borderRadius: 10,
    paddingLeft: 20,
    paddingBottom: 20,
    paddingTop: 10,
    margin: 15
}));

export const Text = styled('div')(({fontWeight, fontSize}) => ({
    fontSize: fontSize ? fontSize + 'em' : '0.8em',
    fontWeight: fontWeight ? fontWeight : 'normal',
    color: 'white'
}));