import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Tooltip} from '@mui/material';

export const ReplayTaskAws = ({information: {label, awsId, url} = {}}) => {

  return <div>
    {label} :
    {!!url && <Tooltip title="Ouvrir la console AWS (Equipe technique uniquement)" placement="bottom" arrow>
      <OpenInNewIcon
        onClick={() => window.open(url, '_blank')}
        sx={{cursor: 'pointer', pt: 1}}
      />
    </Tooltip>}
    <br/>
    <span style={{fontSize: 10}}>{awsId}</span>
  </div>
}
