import React from 'react';
import { AutocompleteInput, ReferenceInput, required } from 'react-admin';
import { EditGuesser, InputGuesser } from '@api-platform/admin';

const EditDifficulties = (({ classes, ...props }) => {

  const disableWheel = () => {
    document.activeElement.blur()
  }

  return (
    <EditGuesser  undoable={true} {...props} title={'Créer une catégorie'}>
        <InputGuesser
          source="name"
          label="Nom"
          onWheel={() => disableWheel()}
          validate={[required()]}
        />
        <ReferenceInput
          label="Categorie"
          reference="categories"
          source="category"
          allowEmpty={true}
          disabled
          >
          <AutocompleteInput optionText="name" validate={[required()]}/>
        </ReferenceInput>
    </EditGuesser>
  );
});

export default EditDifficulties;
