import React from 'react';
import {
  BulkExportButton,
  FunctionField,
  DateField,
  NumberField,
  TextInput,
  DateInput,
  List,
  TextField,
  Datagrid,
  EditButton,
  ShowButton,
} from 'react-admin';
import { convertMinuteToHours } from '../../utils/Date';
import { OfferFilterSidebar } from '../offers/OfferSearch';
import SaveFooterOffersButton from './components/SaveFooterOffersButton';



const PostBulkActionButtons = props => (
  <>
    <SaveFooterOffersButton {...props}/>
    <BulkExportButton {...props} />
  </>
);

const offerFilters = [
  <TextInput label="Nom de l'offre" source="title" name="title" />,
  <TextInput label="Url (slug)" source="slug" name='slug'  />,
  <TextInput label="Status de l'offre" source="status" name="Status" />,
  <DateInput
    source="created_at[after]"
    label="Date de création (Début)"
    options={{
      mode: 'portrait',
      locale: 'nl',
    }}
    name="created_at[after]"
  />,
  <DateInput
    source="created_at[before]"
    label="Date de création (Fin)"
    options={{
      mode: 'portrait',
      locale: 'nl',
    }}
    name="created_at[before]"
  />,
  <TextInput label="Type de l'offre" source="type" name="Type"/>
];

const ListOffers = (({ classes, ...props }) => (
  <List
    title="Les offres"
    filters={offerFilters}
    aside={<OfferFilterSidebar />}
    bulkActionButtons={<PostBulkActionButtons/>}
    {...props}
  >
    <Datagrid>
      <TextField source={'title'} label="Titre" />
      <FunctionField
        label="Status"
        render={record => `${record.enabled}` ? "disponible" : "indisponible"}
      />
      <NumberField source={'score_hubspot'} label="Note"/>
      <NumberField source={'participants_number'} label="Nombre de participants"/>
      <TextField source={'type'} label="Type de l'offre" />
      <FunctionField
        label="Domaine"
        render={record => record?.offer_domain?.name}
      />
      <DateField
        label="Date de création"
        source="created_at"
        showTime
        locales="fr-FR"
      />
      <NumberField
        source="price_with_commission_ttc"
        label="Prix Final"
        locales="fr-FR"
        options={{ style: 'currency', currency: 'EUR' }}
      />
      <FunctionField
        label="Durée"
        render={record => `${convertMinuteToHours(record.duration)}`}
      />
      <>
        <EditButton />
        <ShowButton />
      </>
    </Datagrid>
  </List>
));

export default ListOffers;
