export const dayLetters = [
  'dimanche',
  'lundi',
  'mardi',
  'mercredi',
  'jeudi',
  'vendredi',
  'samedi',
];

export const firstLetters = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];

export const monthLetters = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

export const daysLetterCrops = [
  'Lun',
  'Mar',
  'Mer',
  'Jeu',
  'Ven',
  'Sam',
  'Dim',
];

export const Locale = {
  localize: {
    month: n => monthLetters[n],
    day: n => daysLetterCrops[n],
  },
  formatLong: {},
};

export const formattedDateUTC = (date, additionnalDate = 0) => {
  const dateTime = new Date(
    `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`,
  );
  dateTime.setDate(dateTime.getDate() + additionnalDate);
  return `${dateTime.getFullYear()}-${dateTime.getMonth() +
    1}-${dateTime.getDate()}`;
};

export const capitalize = days => {
  return days.charAt(0).toUpperCase() + days.slice(1);
};

export const dateLetterFormat = date => {
  return `${capitalize(dayLetters[date.getDay()])} ${date.getDate()} ${
    monthLetters[date.getMonth()]
  }`;
};

export const dateLetterFormatTime = date => {
  return `${dayLetters[date.getDay()]} ${date.getDate()} ${
    monthLetters[date.getMonth()]
    // eslint-disable-next-line no-use-before-define
  } à ${setZeroTimeHours(date.getHours())}:${setZeroTimeHours(
    date.getMinutes(),
  )}`;
};

export const dateLetterFormatTimeWithYear = date => {
  return `${dayLetters[date.getDay()]} ${date.getDate()} ${
    monthLetters[date.getMonth()]
    // eslint-disable-next-line no-use-before-define
  } ${date.getFullYear()} à ${setZeroTimeHours(
    date.getHours(),
    // eslint-disable-next-line no-use-before-define
  )}:${setZeroTimeHours(date.getMinutes())}`;
};

export const isSameDay = (a, b) => {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
};

export const setZeroTimeHours = date => {
  return `${date < 10 ? '0' + date : date}`;
};

export const setZeroTimeMinutes = date => {
  return `${date < 10 ? date + '0' : date}`;
};

export const getTimeHoursDoubleDot = date => {
  return `${setZeroTimeHours(date.getHours())}:${setZeroTimeMinutes(
    date.getMinutes(),
  )}`;
};

export const getDateWithSeparator = date => {
  return `${setZeroTimeHours(date.getDate())}/${setZeroTimeHours(
    date.getMonth() + 1,
  )}/${date.getFullYear()}`;
};

export const getDateWithSeparatorUS = date => {
  return `${date.getFullYear()}-${setZeroTimeHours(
    date.getMonth() + 1,
  )}-${setZeroTimeHours(date.getDate())}`;
};

export const getDateWithSeparatorWithMonth = date => {
  return `${setZeroTimeHours(date.getDate())}/${setZeroTimeHours(
    date.getMonth() + 1,
  )}`;
};

export const convertMinuteToHours = minute => {
  const hours = Math.floor(minute / 60);
  const minutes = minute % 60;
  return hours + 'h' + setZeroTimeMinutes(minutes);
};

export const transformDate = date => {
  if (!date) return;
  return date.replace(/-/g, '/');
};
