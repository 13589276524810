import { BadgeContainer, Text } from './BadgePie.styled';
import { Avatar } from '@mui/material';

export default function BadgePie({title, number, colorPrimary, colorSecondary}) {
    return (
        <BadgeContainer colorPrimary={colorPrimary} colorSecondary={colorSecondary}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Avatar src={`william?size=32x32`} style={{width: '1.5em', height: '1.5em'}} />
                <Text style={{marginLeft: 10}} fontSize={0.8}>{title}</Text>
            </div>
            <Text style={{marginTop: 10}} fontSize={1} fontWeight={500} align="right">{number}</Text>
        </BadgeContainer>
    )
}
