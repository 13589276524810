import React from 'react';
import Modal from '../../../components/modal/index';

const ModalResetPassword = ({ closeModal, handleResetPassword }) => <Modal onClose={closeModal}>
    <div className='warning-reset-title'>Attention ! ⚠️ <br/></div>
    <div className={'message-reset-password'}>
      Votre action va supprimer le mot de passe actuel de l'utilisateur et lui envoyer un mail avec un mot de passe
      aléatoire, pour qu'il réinitialise son compte.
    </div>
    <div className={'buttons-reset-pass'}>
      <button
        className={'button-reset-password red'}
        onClick={handleResetPassword}
      >
        Réinitialiser le mot de passe
      </button>
      <button
        className={'button-reset-password'}
        onClick={closeModal}
      >
        Annuler
      </button>
    </div>
  </Modal>

export default ModalResetPassword;
