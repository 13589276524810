import CustomSelectInput from '../../../components/SelectInput';
import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const StatuSelector = ({ record, selectedStatus, setSelectedStatus }) => {
  const profileStatusValueToName = (value) => {
    switch (value) {
      case 'student':
        return 'Étudiant';
      case 'retired':
        return 'Retraité';
      case 'other':
        return 'Passionné';
      case 'freelance':
        return 'Freelance (Pro)';
      case 'company':
        return 'Entreprise (Pro)';
      case 'creator':
        return 'Créateur de contenu (Pro)';
      default:
        return '';
    }
  }

  const handleChangeStatus = (event) => {
    const {target: {value}} = event;
    setSelectedStatus(value);
  }

  useEffect(() => {
    if (record?.company) {
      setSelectedStatus(record.company.status);
    }
  }, [record]);

  if (!record?.company) {
    return (<CircularProgress/>)
  }

  return (<CustomSelectInput
    label="Status"
    labelId="label-status"
    options={[
      { name: 'Étudiant', id: 'student' },
      { name: 'Retraité', id: 'retired' },
      { name: 'Passionné', id: 'other' },
      { name: 'Freelance (Pro)', id: 'freelance' },
      { name: 'Entreprise (Pro)', id: 'company' },
      { name: 'Créateur de contenu (Pro)', id: 'creator' }
    ]}
    value={selectedStatus}
    onChange={handleChangeStatus}
  />);
}

export default StatuSelector;
