import {fetchData} from '../overrides/Fetch';
import { getAuthorizationHeader } from '..//utils/CognitoHelpers';

export const fetchUsers = async filters => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/users/${filters}`;
  const headers = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json',
      ...(await getAuthorizationHeader()),
    },
  };

  return fetchData(entrypoint, headers);
};

export const getUsersAndProfiles = async () => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/admin/all_data`;
  const headers = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/merge-patch+json',
      ...(await getAuthorizationHeader()),
    }
  };

  return fetchData(entrypoint, headers);
};

export const fetchReviews = async uid => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}${uid}/reviews`;
  const headers = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json',
      ...(await getAuthorizationHeader()),
    },
  };

  return fetchData(entrypoint, headers);
};

export const fetchUsersWithoutSponsorsMail = async () => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/admin/users-without-sponsor-mail`;

  return fetchData(entrypoint, {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json',
      ...(await getAuthorizationHeader()),
    }
  });
};

export const addUserToProfile = async (email, sponsorUuid) => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/admin/${sponsorUuid}/sponsored-parties`;
  const headers = {
    method: 'POST',
    body: JSON.stringify({email}),
    headers: new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(await getAuthorizationHeader()),
    }),
  }

  return fetchData(entrypoint, headers);
};

export const getSkillerOffers = async ({skillerUri}) => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/admin${skillerUri}/skiller-public-offers`;

  return fetchData(entrypoint, {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json',
      ...(await getAuthorizationHeader()),
    }
  });
};
