import { Card, CardContent } from '@mui/material';
import { FilterList, FilterListItem } from 'react-admin';
import CategoryIcon from '@mui/icons-material/LocalOffer';
import React from 'react';

export const OfferFilterSidebar = () => (
<Card sx={{ order: -1, mr: 2, mt: 9, width: 200 }}>
  <CardContent>
    <FilterList label="Type" icon={<CategoryIcon />}>
      <FilterListItem label="Masterclass" value={{ type: 'masterclass' }} />
      <FilterListItem label="Individuelle" value={{ type: 'individual' }} />
      <FilterListItem label="Collectif" value={{ type: 'collectif' }} />
      <FilterListItem label="Coaching" value={{ type: 'coaching' }} />
      <FilterListItem label="E-learning" value={{ type: 'vod' }} />
      <FilterListItem label="Service" value={{ type: 'service' }} />
    </FilterList>
  </CardContent>
</Card>
);
