import React from 'react';
import { TextInput } from 'react-admin';
import { EditGuesser, InputGuesser } from '@api-platform/admin';

const EditExperiences = (({ classes, ...props }) => {
    return (
        <EditGuesser  undoable={true} {...props} title="Editer une experience">
            <TextInput source="company" label="Company"/>
            <TextInput multiline source="position_title" label="Titre"/>
            <TextInput multiline source="description" label="Description"/>
            <TextInput source="city" label="Ville"/>
            <InputGuesser source="start_date" label="Date de début"/>
            <InputGuesser source="end_date" label="Date de fin"/>
            <InputGuesser source="current_position" label="En cours"/>
        </EditGuesser>
    );
});

export default EditExperiences;
