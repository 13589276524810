import { Box, Paper } from '@mui/material';
import CollectiveOfferAvailabilityExport from "./components/CollectiveOfferAvailabilityExport";
import BasicExportButton from "./components/BasicExportButton.jsx";

const ExportPage = () => {
  return (
    <div>
      <Paper elevation={3}>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'light',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            marginTop: 5,
            padding: 0,
          }}
        >
          <h1>Page des exports</h1>
        </Box>
      </Paper>
      <BasicExportButton
        exportParamName={'allOrders'}
        fileLabel={'vente'}
        label={'Télécharger les ventes'}
        title={'Les ventes'}
      />
      <CollectiveOfferAvailabilityExport />
      <BasicExportButton
        exportParamName={'offersInfos'}
        fileLabel={'offres'}
        label={'Télécharger les offres'}
        title={'Offres du site'}
      />
    </div>
  );
}

export default ExportPage;
