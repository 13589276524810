import React, { useCallback, useEffect, useState } from 'react';
import {
  Create,
  Form,
  required,
  SaveButton,
  TextInput,
  BooleanInput,
} from 'react-admin';
import { Box } from '@mui/material';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { fetchDomains } from '../../routeApi/domains';

const CreateCategory = (({ users, classes, ...props }) => {
  const [value, setValue] = useState([]);
  const [data, setData] = useState([]);

  const onChange = useCallback(
    (e, newValue) => {
      setValue(newValue);
    },
    [setValue],
  );

  useEffect(() => {
    fetchDomains(
      'pagination=false',
    )
      .then(data => {
        const newArray = [];
        for (let domain in data['hydra:member']) {
          newArray.push({
            '@id': data['hydra:member'][domain]['@id'],
            name: data['hydra:member'][domain]['name'],
          });
        }
        setData(newArray);
      })
      .catch(error => {
        console.log('EROR', error);
      });
  }, []);

  const transformDomain = useCallback(
    data => {
      const secondArray = [];
      for (let domain in value) {
        secondArray.push(value[domain]['@id']);
      }
      return {
        ...data,
        domains: secondArray,
      };
    },
    [value],
  );

  const CreateTitle = () => {
    return <span>Créer une catégorie</span>;
  };

  return (
    <React.Fragment>
      <Create {...props} undoable={false} title={<CreateTitle />}>
        <Form
          resource="categories"
>
          <Box component="div" m={5}>
            <TextInput
              source="name"
              label="Nom de la catégorie"
              validate={required()}
              fullWidth
            />
            <Box component="div" m={5}>
              <BooleanInput label="Activé" source="enabled" />
            </Box>
          </Box>
          <Box component="div" m={5}>
            <Autocomplete
              multiple
              options={data}
              getOptionLabel={option => option?.name || option}
              onChange={onChange}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Domaines"
                  placeholder="Ajouter des domaines"
                  margin="normal"
                  fullWidth
                />
              )}
            />
          </Box>
          <Box component="div" m={5}>
            <SaveButton />
          </Box>
        </Form>
      </Create>
    </React.Fragment>
  );
});

export default (CreateCategory);
