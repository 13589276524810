import React, { useEffect, useState } from 'react';
import { FunctionField } from 'react-admin';
import { FieldGuesser, ShowGuesser } from '@api-platform/admin';
import { fetchDomainsByCategory } from '../../routeApi/domains';

const ShowTitle = ({ record }) => {
  return <span>Catégorie - {record ? `${record.name}` : ''}</span>;
};

const ShowCategory = (({ classes, ...props }) => {
  const [domains, setDomains] = useState([]);

useEffect(() => {
  fetchDomainsByCategory(props?.id?.split('/')[2])
  .then(data => {
    const newArray = [];
    data['hydra:member']?.map(domain => {
      newArray.push({
        '@id': domain['@id'],
        name: domain.name
      });
    })
    setDomains(newArray);
  })
  .catch(error => {
    console.log('EROR', error);
  });
}, []);


const Domains = () => (
  <ul>
      {domains?.map(item => (
          <li key={item?.name}>{item?.name}</li>
      ))}
  </ul>
)
Domains.defaultProps = {
  addLabel: true
};

  return (
    <ShowGuesser {...props} title={<ShowTitle />}>
      <FunctionField
        label="id"
        render={record => `${record?.id?.split('/')[2]}`}
      />
      <FieldGuesser source={'name'} label="Catégorie" addLabel={true} />

      <Domains label="Domaines" />

      <FieldGuesser source={'enabled'} addLabel={true} />
    </ShowGuesser>
  );
});

export default ShowCategory;
