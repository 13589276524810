import { Box, Chip, InputLabel, OutlinedInput, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/* options are like this
[
  {name: 'option1', id: 1},
  {name: 'option2', id: 2},
]
 */
const MultiSelectInput = ({ labelId, labelName, onChange, options, value }) => {
  return (<>
    <InputLabel id={labelId}>{labelName}</InputLabel>
      <Select
        labelId={labelId}
        id="demo-multiple-chip"
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map(({name, id}) => (
          <MenuItem key={id} value={name}>{name}</MenuItem>
        ))}
      </Select>
    </>
  )
}

export default MultiSelectInput;
