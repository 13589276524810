import CircularProgress from '@mui/material/CircularProgress';
import CustomSelectInput from '../../../components/SelectInput';
import MultiSelectInput from '../../../components/MultiSelectInput';
import React, { useCallback, useEffect, useState } from 'react';
import { getCategories } from '../../../routeApi/categories';


const CategorySelectors = ({ record, jwtToken, categoriesData, setCategoriesData }) => {
  const [categorySelectedId, setCategorySelectedId] = useState(null);
  const [categories, setCategories] = useState(null);
  const [domainSelectedId, setDomainSelectedId] = useState(null);
  const [domains, setDomains] = useState(null);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [levels, setLevels] = useState(null);

  const handleChangeCategory = (event) => {
    const {target: { value }} = event;
    setCategorySelectedId(value);
    const actualCategory = categories.find((category) => category.id === value);
    setDomains(actualCategory.domains);
    setDomainSelectedId(actualCategory.domains[0].id);
    setCategoriesData({
      categorySelectedId: actualCategory.id,
      domainSelectedId: actualCategory.domains[0].id,
      levelsIds: []
    });
    setSelectedLevels([]);
    setLevels(actualCategory.levels)
  }

  const handleChangeDomain = (event) => {
    const {target: { value }} = event;
    setDomainSelectedId(value);
    setCategoriesData({...categoriesData, domainSelectedId: value});
  }

  const convertLevelsNamesToIds = useCallback((levelsNames) => {
    const actualCategory = categories.find((category) => category.id === categorySelectedId);
    return levelsNames.map((levelName) => {
      const level = actualCategory.levels.find((level) => level.name === levelName);
      return level.id;
    });
  }, [categorySelectedId, categories]);

  const handleChangeLevels = (event) => {
    const {target: { value }} = event;
    setCategoriesData({...categoriesData, levelsIds: convertLevelsNamesToIds(value)});
    setSelectedLevels(typeof value.name === 'string' ? value.split(',') : value);
  }

  //Initialize data
  useEffect(() => {
    if (record && !categories) {
      getCategories().then((categoriesData) => {
        setCategories(categoriesData)
        let actualCategory;
        let categoryRecordId = null;
        let domainRecordId ;
        let difficultyLevelsIds;

        // we just use ids for categories, but at first call react admin give the full object, so when we register once, the category and offer domaines becomes just the uri
        if (record.category['@id']) {
          categoryRecordId = record.category.id;
          difficultyLevelsIds = record.difficulty_levels.map((level) => parseInt(level['@id'].split('/')[2]));
          domainRecordId = record.offer_domain.id;
          actualCategory = categoriesData.find((category) => category.id === record.category.id);
        } else {
          categoryRecordId = parseInt(record.category.split('/')[2]);
          domainRecordId = parseInt(record.offer_domain.split('/')[2]);
          actualCategory = categoriesData.find((category) => category.id === categoryRecordId);
          difficultyLevelsIds = record.difficulty_levels.map((level) => parseInt(level.split('/')[2]));
        }
        setLevels(actualCategory.levels);
        setSelectedLevels(actualCategory.levels.filter(
          (level) =>
            difficultyLevelsIds.includes(level.id)).map((level) => level.name)
        );
        setDomainSelectedId(domainRecordId);
        setCategorySelectedId(categoryRecordId);
        setCategoriesData({
          categorySelectedId: categoryRecordId,
          domainSelectedId: domainRecordId,
          levelsIds: difficultyLevelsIds
        });
        setDomains(actualCategory.domains);
      });
    }
  }, [record, categories]);

  if (!categories || !categorySelectedId) {
    return (<CircularProgress/>)
  }

  return <>
    <div className={"selectFieldContainer"}>
      {categorySelectedId && (<CustomSelectInput
        labelId="categorie-select-label"
        value={categorySelectedId}
        label="Catégorie"
        options={categories}
        onChange={handleChangeCategory}
      />)
      }
    </div>
    <div className={"selectFieldContainer"}>
      {domainSelectedId && (<CustomSelectInput
        labelId="domain-select-label"
        value={domainSelectedId}
        label="Sous-catégorie"
        options={domains}
        onChange={handleChangeDomain}
      />)
      }
    </div>
    <div>
      {levels && <MultiSelectInput
        onChange={handleChangeLevels}
        labelId={'levels-input'}
        labelName={'Niveaux'}
        options={levels}
        value={selectedLevels}
      />}
    </div>
  </>
}

export default CategorySelectors;
