import React from 'react';
import { InputGuesser, EditGuesser } from "@api-platform/admin";

import { BooleanInput } from 'react-admin';

const EditTitle = ({ record }) => {
  return <span>Editer un tag - {record ? `${record.name}` : ''}</span>;
};

const EditSpeciality = (({ classes, ...props }) => (
  <EditGuesser undoable={false} {...props} title={<EditTitle />}>
    <InputGuesser source={'name'} />
    <BooleanInput label="Activé" source="enabled" />
  </EditGuesser>
));

export default EditSpeciality;
