import { Auth } from 'aws-amplify';

const getFreshToken = (session) => {
  return new Promise((resolve) => {
    if (session.getIdToken().getExpiration() < Math.round(+new Date() / 1000)) {
      Auth.currentAuthenticatedUser()
        .then((res) => {
          res.refreshSession(session.getRefreshToken(), (error, data) => {
              resolve(error ? null : data.getIdToken().getJwtToken());
          });
        })
        .catch(() => resolve(null));
      return;
    }
    resolve(session.getIdToken().getJwtToken());
  });
}

export const getJwtToken = async () => {
  return Auth.currentSession()
    .then(getFreshToken)
    .catch(error => {
      console.error(error);
      return null;
    });
}

export const getAuthorizationHeader = async (extraHeaders = {}) => {
  const jwt = await getJwtToken();
  if (jwt) {
    return {
      ...(extraHeaders || {}),
      Authorization: `Bearer ${jwt}`,
    };
  }
  return {};
}

export const getGroups = async () => {
  return Auth.currentSession()
    .then(response => response.getIdToken().payload['cognito:groups'] || [])
    .catch(error => {
      console.error(error);
      return [];
    });
}
