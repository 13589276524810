import React from 'react';
import {
  FunctionField,
  ReferenceField,
  TextField,
  NumberField,
  BooleanField,
  DateField,
  List,
  Datagrid,
  EditButton, ShowButton
} from 'react-admin';

const ListPromotions = (({ classes, ...props }) => (
  <List
    {...props}
    title="Les code promos"
  >
    <Datagrid>
      <FunctionField label="id" render={record => `${record.id.split('/')[2]}`} />
      <TextField source={'title'} />
      <NumberField source={'count_code_used'} addLabel={true} />
      <DateField source={'limit_date'} addLabel={true} />
      <NumberField source={'value'} />
      <FunctionField source={'is_volume'} label={'Unité'} render={record => record.is_volume ? `%` : '€'}/>
      <BooleanField source={'charges_on_skiller'} label={'A charge du skiller'}  />
      <NumberField source={'used_limit'} />
      <>
        <EditButton />
        <ShowButton />
      </>
    </Datagrid>
  </List>
));

export default ListPromotions;
