import React from 'react';
import {
  BulkDeleteButton,
  BulkExportButton,
  FunctionField,
  CreateButton,
  ExportButton,
  TopToolbar,
} from 'react-admin';
import { FieldGuesser, ListGuesser } from '@api-platform/admin';
import { ImportButtonSpecialities } from '../../overrides/importCSV';

const ListActions = props => {
  const {
    resource,
    total,
    currentSort,
    filterValues,
    exporter,
  } = props;
  return (
    <TopToolbar>
      <ImportButtonSpecialities {...props} />
      <CreateButton resource={resource} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </TopToolbar>
  );
};

const PostBulkActionButtons = props => (
  <>
    <BulkDeleteButton {...props} undoable={false} />
    <BulkExportButton {...props} />
  </>
);

const ListSpeciality = (({ classes, ...props }) => (
  <ListGuesser
    {...props}
    title="Les spécialités"
    actions={<ListActions />}
    bulkActionButtons={<PostBulkActionButtons />}>
    <FunctionField label="id" render={record => `${record.id.split('/')[2]}`} />
    <FieldGuesser source={'name'} />
    <FieldGuesser source={'enabled'} />
  </ListGuesser>
));

export default ListSpeciality;
