import React from 'react';
import {
  BulkExportButton,
  DateField,
  DateInput,
  Filter,
  FunctionField,
  NumberField,
  TextField,
  List,
  Datagrid
} from 'react-admin';

const humanStatus = status => {
    switch (status) {
      case 'payment_completed':
        return 'Paiement effectué';
      case 'payout_completed':
        return 'Skiller payé';
      case 'waiting_for_payment':
        return 'En attente du paiement';
      case 'waiting_for_payment_proposed':
        return 'En attente de la proposition de paiement';
      case 'canceled':
        return 'Paiement annulé';
      case 'refunded':
        return 'Client remboursé';
      default:
        return 'No description ' + status;
    }
}

const PostBulkActionButtons = props => (
  <>
    <BulkExportButton {...props} />
  </>
);

const GridFilters = props => (
  <Filter {...props}>
    <DateInput
      source="createdAt[after]"
      label="Date (Aprés)"
      options={{
        mode: 'portrait',
        locale: 'nl',
      }}
    />
    <DateInput
      source="createdAt[before]"
      label="Date (Avant)"
      defaultValue={props.date}
      options={{
        mode: 'portrait',
        locale: 'nl',
        defaultDate: props.date,
      }}
    />
  </Filter>
);

const ListOffers = (({ classes, ...props }) => (
  <List
    title="Les commandes"
    bulkActionButtons={<PostBulkActionButtons />}
    filters={<GridFilters />}
    {...props}
  >
    <Datagrid>
      <DateField source={'created_at'} label="Date de commande" showTime />
      <TextField
        label="Skiller"
        source={"skiller_public_name"}
      />
      <NumberField
        source="final_skiller_price_ttc"
        label="Revenu"
        locales="fr-FR"
        options={{ style: 'currency', currency: 'EUR' }}
      />
      <FunctionField label="Client" render={record => `${record?.buyer?.public_name + ':' + record?.buyer?.email}`} />
      <NumberField
        source="final_price_ttc"
        label="Coût"
        locales="fr-FR"
        options={{ style: 'currency', currency: 'EUR' }}
      />
      <FunctionField label="Offre" render={record => `${record?.offer?.title}`} />
      <FunctionField
        label="Status"
        render={record => humanStatus(record.status)}
      />
    </Datagrid>
  </List>
));

export default ListOffers;
