import {getOfferRelaysList} from '../replays/api';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {Alert, Button, Tooltip} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {DataGrid} from '@mui/x-data-grid';
import {useSearchParams} from 'react-router-dom';
import moment from 'moment/moment';
import {ReplayStatus} from '../replays/ReplayStatus';

const OFFER_REPLAY_LIST_STATE_LOADING = 'loading';
const OFFER_REPLAY_LIST_STATE_LOADED = 'loaded';
const OFFER_REPLAY_LIST_STATE_ERROR = 'error';

export const OfferReplays = ({offerId}) => {
  const [state, setState] = useState(OFFER_REPLAY_LIST_STATE_LOADING);
  const [list, setList] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPage, setTotalPage] = useState(0);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const page = useMemo(() => parseInt(searchParams.get('page') || 1), [searchParams]);

  const loadOfferReplays = useCallback(() => {
    setState(OFFER_REPLAY_LIST_STATE_LOADING);
    getOfferRelaysList(offerId).then(({list, pageSize, numberOfPages, isSuperAdmin}) => {
      setList(list)
      setPageSize(pageSize);
      setTotalPage(numberOfPages)
      setIsSuperAdmin(isSuperAdmin)
      setState(OFFER_REPLAY_LIST_STATE_LOADED);
    }).catch(error => {
      console.error(error);
      setState(OFFER_REPLAY_LIST_STATE_ERROR);
    });

  }, [offerId]);

  useEffect(() => {
    if (!offerId) {
      return;
    }
    loadOfferReplays();
  }, [offerId]);


  const columns = useMemo(() => [
    {
      field: 'id',
      headerName: 'Live Id',
      width: 250,
      sortable: false,
      renderCell: ({value}) => <span style={{fontSize: 10}}>{value}</span>
    },
    {
      field: 'liveAt',
      headerName: 'Live démarré à',
      width: 150,
      sortable: false,
      renderCell: ({value}) => moment(value).format('DD/MM/YYYY à HH[h]mm')
    },
    {
      field: 'replayTitle',
      headerName: 'Titre du replay',
      width: 250,
      sortable: false,
      renderCell: ({value}) => <Tooltip
        title={value}
        placement="bottom"
        arrow
      >{value}</Tooltip>
    },
    {
      field: 'status',
      headerName: 'Etat',
      width: 220,
      sortable: false,
      renderCell: ({value, row: {lastVersion, skillerWantRecord}}) => <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 5}}>
        <ReplayStatus
          status={value}
          version={lastVersion}
          skillerWantRecord={skillerWantRecord}
        />
      </div>
    },
    isSuperAdmin && {
      headerName: 'Actions',
      width: 150,
      sortable: false,
      renderCell: ({id}) => <Button
        color="primary"
        href={`#/replays/${id}`}
        size="small"
        sx={{marginRight: 1}}
        variant="contained"
      >
        Afficher
        <KeyboardArrowRightIcon sx={{marginLeft: 1}}/>
      </Button>
    }
  ].filter(Boolean), [page, isSuperAdmin]);


  return <>
    {state === OFFER_REPLAY_LIST_STATE_LOADING && <CircularProgress/>}

    {state === OFFER_REPLAY_LIST_STATE_ERROR && <Alert severity="error">
      Impossible de récupérer la liste des replays de cette offre.
      Veuillez vérifier que vous êtes bien connecté.
      <br/>
      <Button
        onClick={loadOfferReplays}
        sx={{marginTop: 2}}
        variant="contained"
      >Recommencer</Button>
    </Alert>}

    {state === OFFER_REPLAY_LIST_STATE_LOADED && !list.length && <Alert severity="info">Aucun replay disponible</Alert>}

    {((state === OFFER_REPLAY_LIST_STATE_LOADED && list.length > 0) || state === OFFER_REPLAY_LIST_STATE_LOADING) &&
      <DataGrid
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize,
            },
          },
        }}
        loading={state === OFFER_REPLAY_LIST_STATE_LOADING}
        rows={list}
        disableColumnFilter
        disableColumnMenu
        hideFooter
      />}

    {state === OFFER_REPLAY_LIST_STATE_LOADED && <div style={{textAlign: 'center'}}>
      {page > 1 && <Button
        onClick={() => setSearchParams(current => ({ ... current, page: page - 1 }))}
        sx={{float: 'left', marginTop: 1}}
        variant="outlined"
      >
        <KeyboardArrowLeftIcon sx={{marginRight: 1}}/> Précédent
      </Button>}
      {page < totalPage && <Button
        onClick={() => setSearchParams(current => ({ ... current, page: page + 1 }))}
        sx={{float: 'right', marginTop: 1}}
        variant="outlined"
      >
        Suivant <KeyboardArrowRightIcon sx={{marginLeft: 1}}/>
      </Button>}
      {totalPage > 1 && <div style={{paddingTop: 15}}>Page {page} / {totalPage}</div>}
    </div>}
  </>;
}
