import React, {useEffect, useState} from 'react';
import {
  BooleanField,
  DateField,
  FunctionField,
  NumberField,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
  ReferenceOneField,
} from 'react-admin';
import { ShowGuesser } from "@api-platform/admin";
import { typeOffer } from '../../utils/Calendar';
import {
  convertMinuteToHours,
} from '../../utils/Date';
import ListOrders from "../orders/ListOrders";
import { OfferReplays } from './OfferReplays';

const ShowOrders = ({id}) => (
  <ListOrders
    filter={{offer_uuid: id}}
    resource="orders"
    title="Les commandes"
    exporter={true}
  />
);

const RecordGetter = ({onRecord}) => {
  const record = useRecordContext();
  useEffect(() => {
    onRecord(record)
  }, [record]);
}

const ShowOffers = (({classes, ...props}) => {
  const [record, setRecord] = useState(null);
  const canHaveReplay = record?.type === 'masterclass' || record?.type === 'collectif';

  return (
    <ShowGuesser {...props}>
      <RecordGetter onRecord={setRecord} />
      <TabbedShowLayout>
        <Tab label="Informations">
          <FunctionField
            label="Date de suppression"
            source="deleted_at"
            style={{
              color: 'red',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
            render={record =>
              record?.deleted_at !== null
                ? `Cette offre a été supprimé le ${new Date(
                  record?.deleted_at,
                ).toLocaleString()}`
                : ''
            }
          />
          <FunctionField
            label="id"
            render={record => `${record.id.split('/')[2]}`}
          />
          <TextField label="Titre" source="title" />
          <TextField label="Description" source="description" />
          <FunctionField
            label="Skiller"
            render={record => `Nom: ${record?.professional.public_name}\n Email: ${record?.professional.email}`}
          />
          <ReferenceOneField
            label="Lien skiller"
            target="profile"
            reference="profiles"
            link={record => `${process.env.REACT_APP_ADMIN_ENTRYPOINT}/users/%2Fusers%2F${record.profile.owner_uuid.split('/')[2]}/show`}
          >
            Infos du skiller
          </ReferenceOneField>

          <BooleanField label="Offre activée" source="enabled" />
          <NumberField
            label="Note"
            source="score_hubspot"
          />
          <br/>
          <FunctionField render={data => `${typeOffer[data.type]}`} source={'type'} label="Type de l'offre" />
          {record?.type === 'masterclass' && (
            <FunctionField
              label="Nombe de places"
              render={record =>
                `${record?.limit_number}`
              }
            />
          )}
          <DateField
            label="Date de création"
            source={'created_at'}
            showTime
            locales="fr-FR"
          />
          <DateField
            label="Date de modification"
            source={'updated_at'}
            showTime
            locales="fr-FR"
          />
          <NumberField
            source="price_ht"
            label="Prix HT"
            locales="fr-FR"
            options={{ style: 'currency', currency: 'EUR' }}
          />
          <FunctionField
            label="Durée"
            render={record => `${convertMinuteToHours(record?.duration)}`}
          />
        </Tab>
        <Tab label="Commandes">
          <SimpleShowLayout>
            <ShowOrders id={record?.id}/>
          </SimpleShowLayout>
        </Tab>
        {canHaveReplay && <Tab label="Replays" path="replays">
          <SimpleShowLayout>
            <OfferReplays offerId={record?.id?.split('/')[2]} />
          </SimpleShowLayout>
        </Tab>}
      </TabbedShowLayout>
    </ShowGuesser>
  );
});

export default (ShowOffers);
