import { Route } from "react-router-dom";
import { parseHydraDocumentation } from '@api-platform/api-doc-parser';
import {
  hydraDataProvider as baseHydraDataProvider,
  fetchHydra as baseFetchHydra,
  AdminGuesser,
  ResourceGuesser,
  hydraSchemaAnalyzer
} from '@api-platform/admin';
import { useCallback, useEffect, useState } from "react";
import { CustomRoutes, memoryStore, defaultTheme } from "react-admin";
import ListAdmin from "../../api/admin/ListAdmin";
import CreateAdmin from "../../api/admin/CreateAdmin";
import ShowAdmin from "../../api/admin/ShowAdmin";
import EditAdmin from "../../api/admin/EditAdmin";
import LockIcon from "@mui/icons-material/Lock";
import LogoutButton from "../../pages/logoutButton";
import ListUsers from "../../api/users/ListUsers";
import CreateUsers from "../../api/users/CreateUsers";
import EditUsers from "../../api/users/EditUsers";
import ShowUsers from "../../api/users/ShowUsers";
import PersonIcon from "@mui/icons-material/Person";
import ListProfiles from "../../api/profiles/ListProfiles";
import ShowProfiles from "../../api/profiles/ShowProfiles";
import EditProfiles from "../../api/profiles/EditProfiles";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import ListOffers from "../../api/offers/ListOffers";
import ShowOffers from "../../api/offers/ShowOffers";
import EditOffers from "../../api/offers/EditOffers";
import BallotIcon from "@mui/icons-material/Ballot";
import ListOrders from "../../api/orders/ListOrders";
import PaymentIcon from "@mui/icons-material/Payment";
import ListPromotions from "../../api/promotions/ListPromotions";
import CreatePromotions from "../../api/promotions/CreatePromotions";
import EditPromotions from "../../api/promotions/EditPromotions";
import ShowPromotions from "../../api/promotions/ShowPromotions";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ListCategory from "../../api/categories/ListCategory";
import CreateCategory from "../../api/categories/CreateCategory";
import EditCategory from "../../api/categories/EditCategory";
import ShowCategory from "../../api/categories/ShowCategory";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ListDomain from "../../api/domains/ListDomain";
import CreateDomain from "../../api/domains/CreateDomain";
import EditDomain from "../../api/domains/EditDomain";
import ShowDomain from "../../api/domains/ShowDomain";
import PublicIcon from "@mui/icons-material/Public";
import ListSpeciality from "../../api/specialities/ListSpeciality";
import CreateSpeciality from "../../api/specialities/CreateSpeciality";
import EditSpeciality from "../../api/specialities/EditSpeciality";
import ShowSpeciality from "../../api/specialities/ShowSpeciality";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ListDifficulties from "../../api/difficulties/ListDifficulties";
import CreateDifficulties from "../../api/difficulties/CreateDifficulties";
import EditDifficulties from "../../api/difficulties/EditDifficulties";
import ListTags from "../../api/tags/ListTags";
import CreateTags from "../../api/tags/CreateTags";
import EditTags from "../../api/tags/EditTags";
import EditExperiences from "../../api/experiences/EditExperiences";
import EditReviews from "../../api/reviews/EditReviews";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Layout from '../../layout/Layout';
import Dashboard from "../../pages/homepage/Dashboard";
import LabelIcon from "@mui/icons-material/Label";
import { i18nProvider } from "../../providers/i18nProvider";
import Replays from '../../api/replays/Replays';
import Replay from '../../api/replays/Replay';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

import React from 'react';
import { CognitoAuthProvider } from 'ra-auth-cognito';
import { CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { getAuthorizationHeader, getGroups } from '../../utils/CognitoHelpers';
import { Connection } from '../../api/users/Connection';
import { GoToWebsite } from '../../api/users/GoToWebsite';
import { GoToBackofficeV2 } from '../../api/users/GoToBackofficeV2';
import ExportPage from '../../pages/ExportPage';
import GetAppIcon from '@mui/icons-material/GetApp';

const themeWooskill = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: 'rgb(18, 12, 48)',
    },
    secondary: {
      main: 'rgb(18, 12, 48)',
    },
  },
  overrides: {
    MuiToolbar: {
      dense: {
        color: '#fff',
      },
    },
    MuiButton: {
      containedPrimary: {
        color: '#fff',
      },
    },
  },
};

const darkTheme = { ...defaultTheme, palette: { mode: 'dark' } };

export const entrypoint = process.env.REACT_APP_ENTRYPOINT;

const Admin = () => {
  const [roles, setRoles] = useState([]);

  const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  });
  const authProvider = CognitoAuthProvider(userPool);

  const { user } = useAuthenticator(context => [context.authStatus]);

  const fetchHydra = useCallback(async (url, options = {}) => {
    const headers = await getAuthorizationHeader()
    return baseFetchHydra(url, {
      ...options,
      headers
    });
  }, []);

  const apiDocumentationParser = useCallback(() => async () => {
    const headers = await getAuthorizationHeader()
    try {
      return await parseHydraDocumentation(entrypoint, { headers });
    } catch (result) {
      const { api, response, status } = result;
      if (status !== 401 || !response) {
        throw result;
      }
      return {
        api,
        response,
        status,
      };
    }
  }, [authProvider]);

  const dataProvider = useCallback(() => baseHydraDataProvider({
    entrypoint,
    httpClient: fetchHydra,
    apiDocumentationParser: apiDocumentationParser(),
  }), [user?.username, authProvider]);

  useEffect(() => {
    getGroups()
      .then(roles => setRoles(roles))
      .catch(error => {
        console.error(error);
        setRoles([])
      });
  }, [authProvider?.user?.signInUserSession?.idToken?.jwtToken]);

  return (
    <>
      <title>Wooskill Admin</title>
      <AdminGuesser
        authProvider={authProvider}
        darkTheme={darkTheme}
        dashboard={Dashboard}
        dataProvider={dataProvider()}
        entrypoint={entrypoint}
        i18nProvider={i18nProvider}
        layout={Layout}
        loginPage={() => {
          document.location.href = '/'
        }}
        logoutButton={LogoutButton}
        schemaAnalyzer={hydraSchemaAnalyzer()}
        store={memoryStore()}
        theme={themeWooskill}
      >
        {roles && roles.includes('ROLE_SUPER_ADMIN') &&
          <ResourceGuesser
            name="admin/administrators"
            options={{label: 'Administrateurs'}}
            list={ListAdmin}
            create={CreateAdmin}
            show={ShowAdmin}
            edit={EditAdmin}
            icon={LockIcon}
          />
        }
        <ResourceGuesser
          name="users"
          options={{label: 'Utilisateurs'}}
          list={ListUsers}
          create={CreateUsers}
          edit={EditUsers}
          show={ShowUsers}
          icon={PersonIcon}
          recordRepresentation={(record) => `${record.firstname} ${record.lastname}`}
        />
        <ResourceGuesser
          name="profiles"
          options={{label: 'Profiles'}}
          create={null}
          list={ListProfiles}
          show={ShowProfiles}
          edit={EditProfiles}
          icon={RecentActorsIcon}
        />
        <ResourceGuesser
          name="offers"
          options={{label: 'Offres'}}
          list={ListOffers}
          show={ShowOffers}
          edit={EditOffers}
          icon={BallotIcon}
        />
        <ResourceGuesser
          name="orders"
          options={{ label: 'Commandes' }}
          list={ListOrders}
          create={null}
          edit={null}
          show={null}
          icon={PaymentIcon}
        />
        <ResourceGuesser
          name="promotions"
          options={{ label: 'Code promos' }}
          list={ListPromotions}
          create={CreatePromotions}
          edit={EditPromotions}
          show={ShowPromotions}
          icon={CardGiftcardIcon}
        />

        {/*<ResourceGuesser
          name="appointments"
          options={{ label: 'Les rendez-vous' }}
          list={ListAppointments}
          show={ShowAppointments}
          icon={EventIcon}
        />*/}
        <ResourceGuesser
          name="categories"
          options={{ label: 'Catégories' }}
          list={ListCategory}
          create={CreateCategory}
          edit={EditCategory}
          show={ShowCategory}
          icon={WidgetsIcon}
        />

        {/*<ResourceGuesser
          name="time_availabilities"
        />*/}

        <ResourceGuesser
          name="domains"
          options={{ label: 'Domaines' }}
          list={ListDomain}
          create={CreateDomain}
          edit={EditDomain}
          show={ShowDomain}
          icon={PublicIcon}
          recordRepresentation="name"
        />

        <ResourceGuesser
          name="specialities"
          options={{ label: 'Spécialités' }}
          list={ListSpeciality}
          create={CreateSpeciality}
          edit={EditSpeciality}
          show={ShowSpeciality}
          icon={AutoAwesomeIcon}
        />
        <ResourceGuesser
          name="difficulty_levels"
          options={{label: 'Niveaux'}}
          list={ListDifficulties}
          create={CreateDifficulties}
          edit={EditDifficulties}
        />
        <ResourceGuesser
          name="tags"
          options={{label: 'Tags'}}
          list={ListTags}
          create={CreateTags}
          edit={EditTags}
          show={null}
          icon={LabelIcon}
        />
        <ResourceGuesser
          name="experiences"
          list={null}
          edit={EditExperiences}
          show={null}
        />
        <ResourceGuesser
          name="reviews"
          list={null}
          edit={EditReviews}
          show={null}
        />
        <ResourceGuesser
          name="replays"
          icon={OndemandVideoIcon}
          options={{label: 'Les Replays'}}
        />
        <ResourceGuesser
          name="exports"
          icon={GetAppIcon}
          options={{label: 'Exports'}}
        />
        <ResourceGuesser
          name="bo-v2"
          icon={(p) => <RocketLaunchIcon {...p} sx={{color: 'red'}} />}
          options={{label: 'Backoffice V2'}}
        />
        <CustomRoutes>
          <Route path="/bo-v2" element={<GoToBackofficeV2 />} />
          <Route path="/replays" element={<Replays />} />
          <Route path="/replays/:replayId" element={<Replay />} />
          <Route path="/log-to/:userId" element={<Connection />} />
          <Route path="/go-to-website" element={<GoToWebsite />} />
          <Route path="/exports" element={<ExportPage />} />
        </CustomRoutes>
      </AdminGuesser>
    </>
  );
}
export default Admin;