import {Chip, Tooltip} from '@mui/material';
import {useMemo} from 'react';

const REPLAY_STATE_ERROR = 'error';
const REPLAY_STATE_PROCESSING = 'processing';
const REPLAY_STATE_READY = 'ready';
const REPLAY_STATE_PUBLISHED = 'published';

export const ReplayStatus = ({skillerWantRecord, status, version}) => {
  const chip = useMemo(() => {
    const sx = {padding: 1};
    switch (status) {
      case REPLAY_STATE_ERROR:
        return <Chip
          color="error"
          label="Erreur"
          sx={sx}
        />
      case REPLAY_STATE_PROCESSING:
        return <Chip
          color="warning"
          label="En cours de traitement"
          sx={sx}
        />
      case REPLAY_STATE_READY:
        return <Chip
          color="success"
          label="Prêt - Non publié"
          sx={sx}
        />
      case REPLAY_STATE_PUBLISHED:
        return <Chip
          color="success"
          label={`Publié (Version ${version})`}
          sx={sx}
        />
    }
    return <Chip label="Etat indéterminé"/>

  }, [status, version]);

  return <>
    {chip}
    {skillerWantRecord && <Tooltip
      title="Le skiller a acté avant le live le fait d'enregistrer le live pour avoir un Replay"
      placement="bottom"
      arrow
    >
      <span style={{fontSize: 12, color: 'green', cursor: 'help'}}>Enregistrement activé</span>
    </Tooltip>}
    {!skillerWantRecord && <Tooltip
      title="Le skiller n'a pas souhaité enregistrer le live. Cependant, nous avons le flux vidéo mais le skiller ne peut pas le voir dans ses Replays."
      placement="bottom"
      arrow
    >
      <span style={{fontSize: 12, color: 'red', cursor: 'help'}}>Enregistrement désactivé par le skiller</span>
    </Tooltip>}
  </>
}
