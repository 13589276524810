import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import {
  FunctionField,
  SelectField,
  DateField,
  SimpleShowLayout,
  TabbedShowLayout,
  EmailField,
  Show,
  Datagrid,
  ReferenceManyField,
  EditButton,
  ShowButton,
  TextField,
  NumberField,
  useRecordContext,
  TopToolbar,
  PrevNextButtons,
  BooleanField,
  useShowContext,
  Button
} from 'react-admin';
import { sexeChoices } from '../utils';
import { getLocales } from '../../providers/i18nProvider';
import {
  dateLetterFormatTime
} from '../../utils/Date';
import ListOrders from "../orders/ListOrders";
import { Link } from "@mui/material";
import { fetchReviews } from "../../routeApi/user";
import { SkillerReplays } from "./SkillerReplays";
import { ClientReplays } from "./ClientReplays";
import KeyIcon from '@mui/icons-material/Key';
import { getGroups } from '../../utils/CognitoHelpers';
import CreateIcon from "@mui/icons-material/Create";
import { RemoveRedEye } from "@mui/icons-material";
import splitId from "../../utils/splitId";

const TextArrayField = ({ record, source }) => {
  return (
    <>
      {record[source]?.map(item => (
        <Chip label={item} key={item} />
      ))}
    </>
  );
};
const UrlFieldWooskill = ( props ) =>
{
  const { source, target, rel } = props;
  const record = useRecordContext(props);
  const value = record && record[source];

  if (value === null) {
    return null;
  }

  return (
    <Link href={`${process.env.REACT_APP_LINK}${value}`} target={target} rel={rel}>
      {value}
    </Link>
  );
};

TextArrayField.defaultProps = { addLabel: true };

const ImageFieldArray = ({ record }) => {
  return (
    <div
      style={{
        border: '3px solid black',
        marginLeft: '50px',
        marginTop: '50px',
      }}>
      <img
        src={`${process.env.NEXT_PUBLIC_MEDIA_SERVER}/${record?.content_url}`}
        alt="utilisateur"
        width="500px"
      />
    </div>
  );
};

ImageFieldArray.defaultProps = { addLabel: true };

const ShowTitle = ({ record }) => {
  return (
    <span>
      Utilisateur - {record ? `${record.lastname} ${record.firstname}` : ''}
    </span>
  );
};

const LabelTabOffers = () => {
  const record = useRecordContext();
  const { isLoading } = useShowContext();
  if (isLoading) {
    return <span>Offres</span>;
  }

  return <span>{record.nb_offers} Offres</span>;
}

const RecordGetter = ({onRecord}) => {
  const record = useRecordContext();
  useEffect(() => {
    onRecord(record)
  }, [record]);
}

const ShowOrders = () => {
  const record = useRecordContext();
  const { isLoading } = useShowContext();
  if (isLoading) return null;
  return (
      <SimpleShowLayout>
        <ListOrders
          filter={{buyer_uuid: record.uuid}}
          resource="orders"
          title="Les commandes"
          exporter={false}
        />
      </SimpleShowLayout>
  );
};

const ShowUsers = (({ classes, ...props }) => {
  const [record, setRecord] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [isSuperAdmin, setSuperAdmin] = useState(false);

  useEffect(() => {
      fetchReviews(props.id)
        .then(data => {
          setReviews(data['hydra:member']);
        })
        .catch(error => {
          console.log('ERROR', error);
        });
  }, [record]);

  const ShowSalesOrders = ({ id }) => (
    <ListOrders
      filter={{ saler_uuid: id }}
      resource="orders"
      title="Les ventes"
      exporter={false}
    />
  );

  useEffect(() => {
    getGroups()
      .then(roles => {
        setSuperAdmin((roles || []).includes('ROLE_SUPER_ADMIN'))
      })
      .catch(error => {
        console.error(error);
        setSuperAdmin(false)
      });
  }, []);

  return (
    <>
      <Show {...props} title={<ShowTitle />} actions={
        <TopToolbar>
          {isSuperAdmin && !!record && <Button color="secondary" href={`#/log-to/${record.id.split('/')[2]}`}>
            <KeyIcon sx={{ mr: 1 }} />
              Se connecter à la place de{' '}
              {record.public_name || `${record.firstname} ${record.lastname}`}
            </Button>}
          <div style={{ flex: 1 }} />
          <PrevNextButtons linkType="show" />
        </TopToolbar>
      }
      emptyWhileLoading
      >
        <RecordGetter onRecord={setRecord} />
        <TabbedShowLayout>
          <TabbedShowLayout.Tab label="Informations">
            <FunctionField
              addLabel={false}
              source="profile_picture_url"
              render={record => (
                <img
                  style={{
                    background: 'black',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                  width="120px"
                  height="120px"
                  src={`${process.env.NEXT_PUBLIC_MEDIA_SERVER}/${record?.profile_picture_url}`}
                  alt="profil"
                />
              )}
            />
            <SelectField source="gender" choices={sexeChoices} />
            <TextField source={'firstname'} addLabel={true} />
            <TextField source={'lastname'} addLabel={true} />
            <TextField
              source={'public_name'}
              label="Nom publique"
              addLabel={true}
            />
            <EmailField source={'email'} addLabel={true} />
            <FunctionField
              addLabel={true}
              label="Numéro de téléphone"
              source="phone_number"
              render={record =>
                record?.phone_country_code !== null ?
                    '+' + record?.phone_country_code + record?.phone_number :
                    record?.phone_number
              }
            />
            <DateField
              source={'birth_date'}
              locales={getLocales}
              addLabel={true}
            />
            <DateField
              source="last_activity_at"
              label="Dernière activité"
              locales={getLocales}
              showTime
            />
            <DateField source="created_at" locales={getLocales} showTime />

            { record?.deleted_at !== null && (
              <FunctionField
                addLabel={false}
                label="Date de suppression"
                source="deleted_at"
                style={{
                  color: 'red',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
                render={record =>
                  record?.deleted_at !== null
                    ? `Cette utilisateur a été supprimé le ${new Date(
                      record?.deleted_at,
                    ).toLocaleString()}`
                    : ''
                }
              />
            )}

            { record?.address !== null && (
                <SimpleShowLayout defaultTite="Adresse">
                <TextField
                  source={'address'}
                  label="Adresse"
                  addLabel={true}
                />
                <NumberField
                  source={'post_code'}
                  label="Code postal"
                  addLabel={true}
                />
                <TextField
                  source={'city'}
                  label="Ville"
                  addLabel={true}
                />
              </SimpleShowLayout>
            )}
            <BooleanField
              source={'email_on_new_message'}
              label="Email pour chaque message entrant"
              addLabel={true}
            />
            <BooleanField
              source={'email_on_new_appointment'}
              label="Email pour chaque nouveau rendez-vous"
              addLabel={true}
            />
            <BooleanField
              source={'sms_on_new_message'}
              label="SMS pour chaque message entrant"
              addLabel={true}
            />
            <BooleanField
              source={'sms_before_appointment'}
              label="SMS concernant mes ventes et mes commandes"
              addLabel={true}
            />
          </TabbedShowLayout.Tab>
          { /* TODO Damien rebase */ }
          {
            <TabbedShowLayout.Tab
              label={'Commandes'}
              path="commands"
            >
              <ShowOrders/>
            </TabbedShowLayout.Tab>
          }
          {record?.roles.includes('ROLE_PRO') && (
            <TabbedShowLayout.Tab
              label={`Ventes (${record?.sales_count})`}
              path="sales"
            >
              <SimpleShowLayout>
                <ShowSalesOrders id={record['@id']}/>
              </SimpleShowLayout>
            </TabbedShowLayout.Tab>
          )}
          { /* TODO Damien rebase */ }
          {true && (
            <TabbedShowLayout.Tab label="Stripe" >
              <SimpleShowLayout>
                <TextField
                  source={'stripe_account_id'}
                  label="Compte stripe"
                  addLabel={true}
                />
                <TextField
                  source={'company_name'}
                  label="Nom de la compagnie"
                  addLabel={true}
                />
                <TextField
                  source={'company_legal_status'}
                  label="Status légale"
                  addLabel={true}
                />
                <TextField
                  source={'company_siret'}
                  label="Numéro de SIRET"
                  addLabel={true}
                />
                <TextField
                  source={'company_vat'}
                  label="TVA"
                  addLabel={true}
                />

                <TextField
                  source={'company_billing_address'}
                  label="Adresse de facturation"
                  addLabel={true}
                />
                <NumberField
                  source={'company_billing_post_code'}
                  label="Code postal de facturation"
                  addLabel={true}
                />
                <TextField
                  source={'company_billing_city'}
                  label="Ville de facturation"
                  addLabel={true}
                />
              </SimpleShowLayout>
            </TabbedShowLayout.Tab>
          )}
          { /* TODO Damien rebase */ }
          { true && (
            <TabbedShowLayout.Tab
              label={`Ses avis (${record?.total_reviews})`}
              path="reviews">
                <SimpleShowLayout>
                {reviews?.map(review => (
                  <>
                    <strong>Avis du client</strong>
                    <table border="1" cellSpacing="0" cellPadding="3">
                      <thead>
                        <tr>
                          <th>Utilisateur</th>
                          <th>Commentaire</th>
                          <th>Note</th>
                          <th>Date de création</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{review?.owner.public_name}</td>
                          <td>{review?.comment}</td>
                          <td>{review?.rating}/5</td>
                          <td>
                            {' '}
                            {dateLetterFormatTime(new Date(review.created_at))}
                          </td>
                          <td>
                            <EditButton to={`/reviews/${encodeURIComponent(review['@id'])}`} />
                          </td>
                        </tr>
                      </tbody>
                    </table>{' '}
                    <br />
                    {review?.answer !== null && (
                      <SimpleShowLayout>
                        <strong>Commentaire du skiller</strong>
                        <table border="1" cellSpacing="0" cellPadding="3">
                          <thead>
                            <tr>
                              <th>Utilisateur</th>
                              <th>Commentaire</th>
                              <th>Date de création</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{review?.answer?.owner.public_name}</td>
                              <td>{review?.answer?.comment}</td>
                              <td>
                                {' '}
                                {dateLetterFormatTime(
                                  new Date(review?.answer?.created_at),
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </SimpleShowLayout>
                    )}
                    <br />
                    <hr />
                    <br />
                  </>
                ))}
                </SimpleShowLayout>
            </TabbedShowLayout.Tab>
          )}
          { /* TODO Damien rebase */ }
          { true && (
            <TabbedShowLayout.Tab label={`Profiles (${record?.number_profiles})`} path="profils">
              <ReferenceManyField reference="profiles" target="owner_uuid" label={false}>
                <Datagrid>
                  <NumberField label={'Id'} source={'db_id'}/>

                  <TextField label={'Nom'} source={'name'}/>
                  <NumberField label={'Présentation'} source={'presentation_title'}/>
                  <TextField label={'Status'} source={'status'}/>
                  <DateField label={'Date de dernière mise à jour'} source={'updated_at'}/>
                  <UrlFieldWooskill
                    source={'url'}
                    target='_blank' // New window
                    rel="noopener noreferrer" // For security
                  />
                  <DateField label={'date de suppression'} source={'deleted_at'}/>
                  {record && record.uuid_profiles_path[0] && <div style={{ display: 'flex', alignItems: 'center', gap: 30}}>
                    <a href={`/v2/profiles/show.html?uuid=${splitId(record?.uuid_profiles_path[0])}&mode=edit`}>
                      <CreateIcon color="primary"/>
                    </a>
                    <a href={`/v2/profiles/show.html?uuid=${splitId(record?.uuid_profiles_path[0])}&mode=show`}>
                      <RemoveRedEye color="primary"/>
                    </a>

                  </div>}
                </Datagrid>
              </ReferenceManyField>
            </TabbedShowLayout.Tab>
          )}
          { /* TODO Damien rebase */ }
          { true && (
            <TabbedShowLayout.Tab label={<LabelTabOffers />} path="offres">
              <ReferenceManyField reference="offers" target="professional_uuid" label={false}>
                <Datagrid>
                  <NumberField label={'Id'} source={'db_id'}/>

                  <TextField label={'Titre'} source={'title'}/>
                  <TextField label={'Status'} source={'status'}/>
                  <TextField source={'type'} label="Type" />
                  <NumberField label={'Nombre de visite'} source={'vue_count'}/>

                  <DateField label={'Date de dernière mise à jour'} source={'updated_at'}/>
                  <UrlFieldWooskill
                    source={'url'}
                    target='_blank' // New window
                    rel="noopener noreferrer" // For security
                  />
                  <DateField label={'date de suppression'} source={'deleted_at'}/>
                  <BooleanField label={'Mis en avant'} source={'is_highlighted'}/>
                  <ShowButton />
                  <EditButton />
                </Datagrid>
              </ReferenceManyField>
            </TabbedShowLayout.Tab>
          )}

          <TabbedShowLayout.Tab label={`Replays (Skiller)`} path="replays/skiller">
              <SkillerReplays userId={record?.id?.split('/')[2]} />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label={`Replays (Client)`} path="replays/client">
              <ClientReplays userId={record?.id?.split('/')[2]} />
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
});

export default (ShowUsers);
