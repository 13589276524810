import React, { cloneElement, useEffect, useState } from 'react';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  downloadCSV,
  BulkExportButton,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  BooleanField,
  List,
  EditButton,
  ShowButton
} from 'react-admin';
import { Rating } from '@mui/material';
import Chip from '@mui/material/Chip';
import { getLocales } from '../../providers/i18nProvider';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import * as moment from 'moment';
import { fetchUsers } from '../../routeApi/user';
import ListProfiles from "../profiles/ListProfiles";
import { PostFilterSidebar } from './PostFilterSidebar';

const RoleField = () => {
  const { record } = useListContext();

  return (
    <ul>
      {record?.roles?.map(role => (
        <li><Chip label={role} key={role} /></li>
      ))}
    </ul>
  );
}

const RatingActivityField = ({ record }) => {
  return (
    <Rating name="customized-6" defaultValue={record?.progress_level || null} max={6} disabled />
  )
}

const exporter = posts => {
  const postsForExport = posts.map(post => {
    const last_logon =
      post.last_logon === null
        ? ''
        : moment(post.last_logon).format('DD-MM-YYYY H:mm');
    const last_activity_at =
      post.last_activity_at === null
        ? ''
        : moment(post.last_activity_at).format('DD-MM-YYYY H:mm');
    const dateStringDelete =
      post.deleted_at !== null
        ? moment(post.deleted_at).format('DD-MM-YYYY H:mm')
        : '';
    return {
      email: post.email,
      firstname: post.firstname,
      lastname: post.lastname,
      address: post.address,
      roles: post.roles,
      company_legal_status: post.company_legal_status,
      deleted_at: dateStringDelete,
      last_activity_at: last_activity_at,
      last_logon: last_logon,
    };
  });

  const csv = convertToCSV({
    data: postsForExport,
    fields: [
      'email',
      'firstname',
      'lastname',
      'address',
      'roles',
      'company_legal_status',
      'deleted_at',
      'last_activity_at',
      'last_logon',
    ],
  });

  downloadCSV(csv, `listeUtilisateurs_${moment().format('DD_MM_YYYY__H-mm')}`);
};

const ListActions = props => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton resource={resource} />
      <ExportButton
        disabled={total === 0 || maxResults === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const PostBulkActionButtons = props => (
  <>
    <BulkExportButton {...props} />
  </>
);

const ListUsers = (({ classes, ...props }) => {
  const [countUser, setCountUser] = useState(0);
  useEffect(() => {
    fetchUsers('')
      .then(data => {
        setCountUser(data['hydra:totalItems']);
      })
      .catch(error => {
        console.log('ERROR', error);
      });
  }, []);

  const ShowProfiles = ({ id }) => (
    <ListProfiles
      filter={{ owner_uuid: id }}
      resource="profiles"
      pagination={false}
      title="Les profils"
      exporter={false}
    />
  );

  return (
    <List
      aside={<PostFilterSidebar {...props} />}
      filters={null}
      {...props}
      bulkActionButtons={<PostBulkActionButtons />}
      actions={<ListActions maxResults={countUser} />}
      exporter={exporter}
      filterDefaultValues={{ roles: "ROLE_PRO" }}
      title="Les utilisateurs"
      expand={<ShowProfiles />}
    >
      <Datagrid>
        <NumberField label={'Id'} source={'db_id'} />
        <TextField source={'firstname'} />
        <TextField source={'lastname'} />
        <TextField source={'email'} />
        <DateField locales={getLocales} source={'last_logon'} />

        <RatingActivityField
          label="Etat d'avancement"
        />
        <RoleField />
        <BooleanField
          label="Compte Stripe"
          source="has_stripe_accounts"
        />
        <TextField
          label="Stripe ID"
          source="stripe_account_id"
        />
        <>
          <EditButton />
          <ShowButton />
        </>
      </Datagrid>
    </List>
  );
});

export default (ListUsers);