import * as React from 'react';
import {useCallback} from 'react';
import {Button, Tooltip} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { UserReplays } from './UserReplays';
import moment from 'moment/moment';

export const SkillerReplays = ({userId}) => {
  const columnsRenderer = useCallback((page, isSuperAdmin) => [
    {
      field: 'id',
      headerName: 'Live Id',
      width: 250,
      sortable: false,
      renderCell: ({value}) => <span style={{fontSize: 10}}>{value}</span>
    },
    {
      field: 'liveAt',
      headerName: 'Live démarré à',
      width: 150,
      sortable: false,
      renderCell: ({value}) => moment(value).format('DD/MM/YYYY à HH[h]mm')
    },
    {
      field: 'offerTitle',
      headerName: 'Titre de l\'offre',
      width: 250,
      sortable: false,
      renderCell: ({value}) => <Tooltip
        title={value}
        placement="bottom"
        arrow
      >{value}</Tooltip>
    },
    {
      field: 'replayTitle',
      headerName: 'Titre du replay',
      width: 250,
      sortable: false,
      renderCell: ({value}) => <Tooltip
        title={value}
        placement="bottom"
        arrow
      >{value}</Tooltip>
    },
    isSuperAdmin && {
      headerName: 'Actions',
      width: 150,
      sortable: false,
      renderCell: ({id}) => <Button
        color="primary"
        href={`#/replays/${id}`}
        size="small"
        sx={{marginRight: 1}}
        variant="contained"
      >
        Afficher
        <KeyboardArrowRightIcon sx={{marginLeft: 1}}/>
      </Button>
    }
  ].filter(Boolean), []);

  return <UserReplays
    columnsRenderer={columnsRenderer}
    type="skiller"
    userId={userId}
  />;
}
