import { HttpError } from 'react-admin';
import {
  fetchJsonLd,
  getDocumentationUrlFromHeaders,
} from '@api-platform/api-doc-parser';
import { promises } from 'jsonld';
import { getJwtToken } from "../utils/CognitoHelpers";

const fetchHydra = async (url, options = {}) => {
  const requestHeaders = options.headers || new Headers();

  requestHeaders.set('Authorization', `Bearer ${await getJwtToken()}`);

  return fetchJsonLd(url, {
    ...options,
    headers: requestHeaders,
  }).then(data => {
    const status = data.response.status;
    if (status < 200 || status >= 300) {
      return promises
        .expand(data.body, {
          base: getDocumentationUrlFromHeaders(data.response.headers),
        })
        .then(json => {
          return Promise.reject(
            new HttpError(
              json[0]['http://www.w3.org/ns/hydra/core#description'][0][
                '@value'
              ],
              status,
            ),
          );
        })
        .catch(error => {

          const getErrors = data.body.violations;

          if (error.hasOwnProperty('body')) {
            return Promise.reject(error);
          }

          for (const errors in getErrors) {
            return Promise.reject(
              new HttpError(getErrors[errors].message, status),
            );
          }
        });
    }

    return {
      status: status,
      headers: data.response.headers,
      json: data.body,
    };
  });
};

export default fetchHydra;
