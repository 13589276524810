import React from 'react';
import {
  BooleanInput,
  Edit,
  Form,
  NumberInput,
  TextInput,
  required,
  SaveButton,
  AutocompleteArrayInput,
  ReferenceArrayInput
} from 'react-admin';
import { InputGuesser } from '@api-platform/admin';

const EditTitle = ({ record }) => {
  return <span>Editer une catégorie - {record ? `${record.name}` : ''}</span>;
};

const EditCategory = (({ classes, ...props }) => {
  return (
      <Edit {...props} undoable={false} title={<EditTitle />}>
        <Form resource="categories">
          <TextInput
            source="name"
            label="Nom de la catégorie"
            validate={required()}
            fullWidth
          />
          <NumberInput
            fullWidth
            label="Tarif max particulier conseillé"
            source="price_max_particular"
          />
          <NumberInput
            fullWidth
            label="Tarif max pro conseillé"
            source="price_max_pro"
          />
          <InputGuesser label={"URL de la landing page"} source={"landing_page_url"} fullWidth/>
          <BooleanInput source={"is_prioritized"} label="Catégorie mise en avant" fullWidth/>
          <ReferenceArrayInput source="domains" reference="domains" perPage={500}>
            <AutocompleteArrayInput optionText="name"
                                    format={value => value && value.map(v => v['@id'])}
                                    parse={value =>
                                      value && value.map(v => ({ '@id': v }))
                                    }
            />
          </ReferenceArrayInput>
          <SaveButton />
        </Form>
      </Edit>
  );
});

export default EditCategory;
