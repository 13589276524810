import {
  ThemeProvider,
  Theme,
  useTheme,
} from '@aws-amplify/ui-react';

export function DarkAmplifyProvider({ children }) {
  const { tokens } = useTheme();
  const darkMode= {
    name: 'Auth Example Theme',
    tokens: {
      label: {
        color: { value: '{colors.brand.primary[10]}' },
      },
      colors: {
        background: {
          primary: {
            value: tokens.colors.neutral['90'].value,
          },
          secondary: {
            value: tokens.colors.neutral['100'].value,
          },
        },
        font: {
          interactive: {
            value: tokens.colors.white.value,
          },
        },
        brand: {
          primary: {
            '10': tokens.colors.teal['100'],
            '80': tokens.colors.teal['40'],
            '90': tokens.colors.teal['20'],
            '100': tokens.colors.teal['10'],
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: tokens.colors.white.value,
              },
            },
            _hover: {
              color: {
                value: tokens.colors.yellow['80'].value,
              },
            },
            _active: {
              color: {
                value: tokens.colors.white.value,
              },
            },
          },
        },
      },
    },
  };

  return (
    <ThemeProvider theme={darkMode}>
      {children}
    </ThemeProvider>
  );
}
