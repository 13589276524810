import React, { useEffect, useState } from 'react';
import { getSkillerOffers } from '../../../routeApi/user';
import CircularProgress from '@mui/material/CircularProgress';
import SelectInput from '../../../components/SelectInput';

const OfferSelector = ({ selectedOfferUuid, setSelectedOfferUuid, skillerUri }) => {
  const [offers, setOffers] = useState(null);

  useEffect(() => {
    if (skillerUri) {
      getSkillerOffers({ skillerUri })
        .then((data) => {
        setOffers(data);
      });
    }
  }, [skillerUri]);

  if (!offers) {
    return <CircularProgress />
  } if (offers && offers.length === 0) {
    return <strong style={{color: 'red'}}>Ce skiller n'a pas d'offre</strong>
  } else {
    return (
      <SelectInput
        label="Choisir une offre"
        labelId="offer"
        options={offers}
        value={selectedOfferUuid}
        onChange={(event) => setSelectedOfferUuid(event.target.value)}
      />
    );
  }
}

export default OfferSelector;
