import * as React from 'react';
import { useEffect } from 'react';
import { Box, CircularProgress, Paper } from '@mui/material';

export const GoToBackofficeV2 = () => {

  useEffect(() => {
      document.location.href = `/v2/`;
  }, []);

  return <div>
    <Paper elevation={3}>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'light',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          marginTop: 5,
          padding: 2,
        }}
      >
       <CircularProgress/>
        <br />
        Préparation du nouveau Backoffice...
      </Box>
    </Paper>
  </div>;
}
