import React, { useCallback, useEffect, useState } from 'react';
import {
  Edit,
  Form,
  TextInput,
  required,
  SaveButton,
  BooleanInput
} from 'react-admin';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { Chip } from '@mui/material';
import { fetchSpecialitiesByDomain, fetchSpecialities } from '../../routeApi/specialities';
import { InputGuesser } from '@api-platform/admin';

const EditTitle = ({ record }) => {
  return <span>Editer une catégorie - {record ? `${record.name}` : ''}</span>;
};

const EditDomain = (({ classes, ...props }) => {
  const [data, setData] = useState([]);
  const [specialities, setSpecialities] = useState([]);

  useEffect(() => {
    fetchSpecialitiesByDomain(props?.id?.split('/')[2])
    .then(data => {
      const newArray = [];
      data['hydra:member']?.map(speciality => {
        newArray.push({
          '@id': speciality['@id'],
          name: speciality.name
        });
      })
      setSpecialities(newArray);
    })
    .catch(error => {
      console.log('EROR', error);
    });

    fetchSpecialities(
      'pagination=false',
    )
    .then(data => {
      const newArray = [];
      for (let speciality in data['hydra:member']) {
        newArray.push({
          '@id': data['hydra:member'][speciality]['@id'],
          name: data['hydra:member'][speciality]['name'],
        });
      }
      setData(newArray);
    })
    .catch(error => {
      console.log('ERROR', error);
    });
  }, []);

  const onChange = useCallback(
    (e, newValue) => {
      setSpecialities(newValue);
    },
    [setSpecialities],
  );

  const transform = useCallback(
    data => {
      const secondArray = [];
      for (let speciality in specialities) {
        const typeoffArray = typeof specialities[speciality];
        if (typeoffArray === 'object') {
          secondArray.push(specialities[speciality]['@id']);
        } else {
          secondArray.push({ name: specialities[speciality] });
        }
      }

      return {
        ...data,
        specialities: secondArray,
      };
    },
    [specialities],
  );

  return (
    <React.Fragment>
      <Edit {...props} undoable={false} title={<EditTitle />}>
        <Form
          resource="domains">
            <TextInput
              label="Nom du domaine"
              source="name"
              validate={required()}
              fullWidth
            />
            <InputGuesser label={"URL de la landing page"} source={"landing_page_url"} fullWidth/>
            <BooleanInput source={"is_prioritized"} label="Domaine mis en avant" fullWidth/>
          {specialities && (
              <Autocomplete
                getOptionLabel={option => option?.name || option}
                multiple
                onChange={onChange}
                options={data}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Spécialités"
                    margin="normal"
                    placeholder="Ajouter des spécialités"
                    variant="standard"
                    fullWidth
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      style={{
                        backgroundColor: 'grey',
                        color: 'white',
                      }}
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                value={specialities}
              />
          )}
            <SaveButton />
        </Form>
      </Edit>
    </React.Fragment>
  );
});

export default EditDomain;
