import React from 'react';
import { InputGuesser, EditGuesser } from "@api-platform/admin";
import {
  minValue,
  required,
  useRecordContext
} from 'react-admin';
import { useState } from 'react';
import { useEffect } from 'react';

const EditTitle = ({ record, setIsVolume }) => {
  if(record?.is_volume){
    setIsVolume(true);
  }
  return <span>Editer une promotion - {record ? `${record.title}` : ''}</span>;
};

const EditPromotions = (({ classes, ...props }) => {
  const [isVolume, setIsVolume] = useState(false);
  const [isBanner, setIsBanner] = useState(false);
  const [record, setRecord] = useState(null);

  useEffect(() => {
    if (record?.is_volume) {
      setIsVolume(true);
    }
  }, [record?.is_volume]);

  const validateBannerComment = (value) => {
    if (isBanner && !value) {
        return "Vous devez ajouter une phrase de promotion pour la page d'acceuil";
    }
    return undefined;
  };

  const RecordGetter = ({ onRecord }) => {
    const record = useRecordContext();
    useEffect(() => {
      onRecord(record)
    }, [record]);
  };

  return (
  <EditGuesser undoable={false} {...props} title={<EditTitle setIsVolume={setIsVolume} />}>
    <RecordGetter onRecord={setRecord} />
    <InputGuesser disabled source="title" validate={[required()]} />
    <InputGuesser
      source="value"
      label="Valeur du code promo"
      validate={[required(), minValue(1)]}
      onWheel={() => document.activeElement.blur()}
      disabled={record?.is_divisible}
    />

    <InputGuesser disabled source="is_volume"/>

    {isVolume &&
      <InputGuesser
        source="min_paid_price"
        label="Prix minimum pour bénéficier de la réduction"
        validate={[minValue(1)]}
      />
    }

    {isVolume &&
      <InputGuesser
        source="max_reduction_price"
        label="Réduction maximum (en euro)"
        validate={[minValue(1)]}
        onWheel={() => document.activeElement.blur()}
      />
    }

    <InputGuesser
      source="used_limit"
      label="Maximum de codes utilisables"
      validate={[minValue(1)]}
      onWheel={() => document.activeElement.blur()}
    />

    <InputGuesser
      source="limit_date"
      label="Date limite"
      validate={[required()]}
    />

    <InputGuesser
      source="banner_comment"
      label="Phrase de promotion"
      validate={[validateBannerComment]}
    />

    <InputGuesser
      source="is_divisible"
      label="Coupon sécable"
      disabled
    />

    {record?.owner && <div>
        Propriétaire du code promo: {record.owner.email}
      </div>
    }

  </EditGuesser>
)});

export default EditPromotions;
