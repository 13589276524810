import React, { useCallback, useEffect, useState } from 'react';
import {
  Create,
  Form,
  required,
  SaveButton,
  TextInput,
  BooleanInput,
} from 'react-admin';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { fetchSpecialities } from '../../routeApi/specialities';

const CreateDomain = (({ users, classes, ...props }) => {
  const [value, setValue] = useState([]);
  const [data, setData] = useState([]);

  const onChange = useCallback(
    (e, newValue) => {
      setValue(newValue);
    },
    [setValue],
  );

  useEffect(() => {
    fetchSpecialities(
      'pagination=false',
    )
    .then(data => {
      const newArray = [];
      for (let speciality in data['hydra:member']) {
        newArray.push({
          '@id': data['hydra:member'][speciality]['@id'],
          name: data['hydra:member'][speciality]['name'],
        });
      }
      setData(newArray);
    })
    .catch(error => {
      console.log('EROR', error);
    });
  }, []);

  const transformSpeciality = useCallback(
    data => {
      const secondArray = [];
      for (let speciality in value) {
        secondArray.push(value[speciality]['@id']);
      }
      return {
        ...data,
        specialities: secondArray,
      };
    },
    [value],
  );

  const CreateTitle = () => {
    return <span>Créer un domaine</span>;
  };

  return (
    <React.Fragment>
      <Create {...props} undoable={false} title={<CreateTitle />}>
        <Form resource="domains">
            <TextInput
              label="Nom du domaine"
              source="name"
              validate={required()}
              fullWidth
            />
            <BooleanInput label="Activé" source="enabled" />
            <Autocomplete
              getOptionLabel={option => option?.name || option}
              multiple
              onChange={onChange}
              options={data}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Spécialités"
                  margin="normal"
                  placeholder="Ajouter des spécialités"
                  variant="standard"
                  fullWidth
                />
              )}
            />
            <SaveButton
              transform={transformSpeciality}
            />
        </Form>
      </Create>
    </React.Fragment>
  );
});

export default (CreateDomain);
