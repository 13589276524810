import { FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import CategoryIcon from '@mui/icons-material/LocalOffer';
import React from "react";

export const PostFilterSidebar = () => (
  <Card sx={{ order: -1, width: 300 }}>
    <CardContent>
      <FilterLiveSearch name={"email"} source={"email"} />
      <FilterList label="Role" icon={<CategoryIcon />}>
        <FilterListItem label="Skiller" value={{ roles: 'ROLE_PRO' }} />
        <FilterListItem label="Client" value={{ roles: 'ROLE_USER' }} />
      </FilterList>
    </CardContent>
  </Card>
);
