import React from 'react';
import { SelectArrayInput, PasswordInput, required} from 'react-admin';
import { InputGuesser, CreateGuesser } from "@api-platform/admin";
import { rolesChoices } from '../utils';
import {regex} from 'ra-core';

const validateCountryPhoneNumber = regex(/^\+(?:[0-9] ?){0,14}[0-9]$/, 'Vous devez rentrer un indicatif valide: ex:+33');

const CreateAdmin = (({ classes, ...props }) => (
  <CreateGuesser undoable={true} {...props} title={'Créer un administrateur'}>
      <InputGuesser source={'firstname'} validate={[required()]} />
      <InputGuesser source={'lastname'} validate={[required()]} />
      <InputGuesser source={'email'} validate={[required()]} />
      <PasswordInput
        source="password"
        inputProps={{ autoComplete: 'current-password' }}
        validate={[required()]}
        helperText="ra.validation.helper_password"
      />

      <InputGuesser source={'phone_country_code'}  validate={validateCountryPhoneNumber} />
      <InputGuesser source={'phone_number'} />

      <SelectArrayInput
        source="roles"
        choices={rolesChoices}
        validate={[required()]}
        helperText="ra.validation.helper_roles"
      />

      <InputGuesser source={'enabled'} />
  </CreateGuesser>
));

export default CreateAdmin;