import { fetchData } from '../overrides/Fetch';
import { getAuthorizationHeader } from '../utils/CognitoHelpers';

export const getCategories = async () => {
  const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/admin/categories-data`;
  let headers;

  headers = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json',
      ...(await getAuthorizationHeader()),
    },
  };

  return fetchData(entrypoint, headers);
}
