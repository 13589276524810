import React from 'react';
import { Filter, TextInput, FunctionField, DateField } from 'react-admin';
import { FieldGuesser, ShowGuesser } from '@api-platform/admin';
import { getLocales } from '../../providers/i18nProvider';

const PostFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput label="Firstname" source="firstname" />
  </Filter>
);

const ShowTitle = ({ record }) => {
  return (
    <span>
      Administrateur - {record ? `${record.lastname} ${record.firstname}` : ''}
    </span>
  );
};
const ShowAdmin = (({ classes, ...props }) => (
  <ShowGuesser filters={<PostFilter />} {...props} title={<ShowTitle />}>
    <FunctionField
      addLabel={false}
      label="Date de suppression"
      source="deleted_at"
      style={{
        color: 'red',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      }}
      render={record =>
        record.deleted_at !== null
          ? `Cette administrateur a été supprimé le ${new Date(
              record.deleted_at,
            ).toLocaleString(getLocales)}`
          : ''
      }
    />
    <FunctionField label="id" render={record => `${record.id.split('/')[3]}`} />
    <FieldGuesser source={'firstname'} addLabel={true} />
    <FieldGuesser source={'lastname'} addLabel={true} />
    <FieldGuesser source={'email'} addLabel={true} />
    <FunctionField
      label="Rôle"
      source="roles"
      render={record => `${record.roles[0]}`}
    />

    <FieldGuesser source={'phone_number'} addLabel={true} />
    <FieldGuesser source={'phone_country_code'} addLabel={true} />
    <FieldGuesser source={'enabled'} addLabel={true} />
    <DateField
      source={'created_at'}
      locales={getLocales}
      showTime
      addLabel={true}
    />
    <DateField
      source={'updated_at'}
      locales={getLocales}
      showTime
      addLabel={true}
    />
    <DateField
      source={'last_logon'}
      locales={getLocales}
      showTime
      addLabel={true}
    />
  </ShowGuesser>
));

export default ShowAdmin;
