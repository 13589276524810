import {Amplify, I18n} from 'aws-amplify';

import { Authenticator, translations, useTheme, View, Image } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';


import Admin from './components/admin';
import { DarkAmplifyProvider } from './components/amplify/amplifyTheme';
import './components/amplify/style.css';
import { customErrorMessages } from './cognito/errors';

I18n.putVocabularies(translations);
I18n.setLanguage('fr');

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_BACKOFFICE_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_BACKOFFICE_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    clientMetadata: {
      wooskill_api_host: process.env.REACT_APP_API_HOST,
    }
  },
  errorMessages: customErrorMessages,
});

const formFields = {
  signIn: {
    username: {
      placeholder: 'Saisissez votre email',
      label: 'Email:',
      required: true,
    },
    password: {
      label: 'Mot de pass:',
      placeholder: 'Saisissez votre mot de passe',
      required: true,
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: 'Wooskill Admin ', // Space at end is needed
    }
  }
};

I18n.putVocabularies({
  fr: customErrorMessages
});

const authenticatorComponents = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Wooskill"
          src="/Logo-wooskill-black.svg"
          width={250}
        />
      </View>
    );
  }
}

export default function App() {
  return (
    <DarkAmplifyProvider>
      <Authenticator
        components={authenticatorComponents}
        formFields={formFields}
        hideDefault
        hideSignUp
      >
        <Admin />
      </Authenticator>
    </DarkAmplifyProvider>
  );
}
