import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-admin';
import { Typography } from '@mui/material';
import { saveFooterOffers } from "../../../routeApi/offer";

const SaveFooterOffersButton = ({ selectedIds, ...restProps }) => {
    const [error, setError] = useState('');

    const handleSaveClick = async (event) => {
        event.preventDefault();

        if (selectedIds.length > 3) {
            setError('Vous pouvez sélectionner un maximum de 3 offres pour le footer.');
            return;
        }

        try {
            await saveFooterOffers(selectedIds);
            setError(''); // Clear any existing error
        } catch (error) {
            console.error('Erreur lors de la sauvegarde des offres de footer:', error);
            setError('Une erreur est survenue lors de la sauvegarde des offres de footer.');
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {error && (
                <Typography
                    variant="body2"
                    color="error"
                    style={{ marginRight: '10px' }}
                >
                    {error}
                </Typography>
            )}
            <Button
                label="Sauvegarder les offres de footer"
                onClick={handleSaveClick}
            />
        </div>
    );
};

SaveFooterOffersButton.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default SaveFooterOffersButton;
