// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning-reset-title{
  color: #ff0000;
  display: flex;
  font-size: 2rem;
  font-weight: bold;
  justify-content: center;
  margin-bottom: 1rem;
  text-align: center;
}

.buttons-reset-pass{
  display: flex;
  justify-content: space-between;
}

.button-reset-password{
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  width: 200px;
  &.red{
    background-color: #ff0000;
  }
}

.message {
  color: #ff0000;
  margin-bottom: 20px;
  margin-top: 5px;
}

.message-alert {
  color: #ff0000;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 5px;
}

.message-reset-password {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 5px;
}

.error-message {
  color: #ff0000;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/api/users/style/EditUser.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".warning-reset-title{\n  color: #ff0000;\n  display: flex;\n  font-size: 2rem;\n  font-weight: bold;\n  justify-content: center;\n  margin-bottom: 1rem;\n  text-align: center;\n}\n\n.buttons-reset-pass{\n  display: flex;\n  justify-content: space-between;\n}\n\n.button-reset-password{\n  border: none;\n  border-radius: 5px;\n  color: #fff;\n  cursor: pointer;\n  font-weight: bold;\n  margin-top: 1rem;\n  padding: 0.5rem 1rem;\n  text-align: center;\n  text-decoration: none;\n  width: 200px;\n  &.red{\n    background-color: #ff0000;\n  }\n}\n\n.message {\n  color: #ff0000;\n  margin-bottom: 20px;\n  margin-top: 5px;\n}\n\n.message-alert {\n  color: #ff0000;\n  font-size: 18px;\n  font-weight: 400;\n  margin-bottom: 20px;\n  margin-top: 5px;\n}\n\n.message-reset-password {\n  font-size: 18px;\n  font-weight: 400;\n  margin-bottom: 20px;\n  margin-top: 5px;\n}\n\n.error-message {\n  color: #ff0000;\n  display: flex;\n  font-size: 18px;\n  font-weight: 400;\n  justify-content: center;\n  margin-bottom: 20px;\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
