import React from 'react';
import { Button } from 'react-admin';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useNotify } from 'react-admin';
import fetchHydra from '../fetchHydra';

export const ImportButtonCategorie = props => {
  const notify = useNotify();

  const fetchHeaders = {
    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
  };

  let onFileAdded;
  onFileAdded = async e => {
    const formData = new FormData();
    const file = e.target.files && e.target.files[0];
    formData.append('file', file);
    await fetchHydra(`${process.env.REACT_APP_ENTRYPOINT}/import_categories`, {
      headers: new Headers(fetchHeaders),
      method: 'POST',
      body: formData,
    }).then(e => {
      let cpt = 0;
      if (e?.json?.errors?.length === 0) {
        notify('CSV importé');
        window.location.reload();
      } else {
        // eslint-disable-next-line no-unused-vars
        for (const errors in e?.json?.errors) {
          cpt++;
        }
        if (cpt >= 0) {
          notify(cpt + ' ressource(s) exist(ent) déjà.', 'warning');
          window.location.reload();
        }
      }
    });
  };

  return (
    <>
      <input
        type="file"
        id="text-button-file"
        style={{ display: 'none' }}
        accept=".csv"
        onChange={onFileAdded}
      />
      <label
        htmlFor="text-button-file"
        style={{ display: 'inline-flex', alignItems: 'center' }}>
        <Button color="primary" component="span" label="Import CSV">
          <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: '20' }} />
        </Button>
      </label>
    </>
  );
};
