import React from 'react';

import { Link } from 'react-router-dom';

import { 
  List, 
  ListItem, 
  ListItemAvatar, 
  ListItemText, 
  Avatar 
} from '@mui/material';

export default function ListComponent({list}) {
  return (
    <List>
      {list
        ? list.map(record => (
            <ListItem
              button
              to={`/users/%2Fusers%2F${record.id.split('/')[2]}/show`}
              component={Link}
              key={record.id}
              style={{paddingLeft: 15, paddingBottom: 3, paddingTop: 3}}>
              <ListItemAvatar style={{minWidth: 0, marginRight: 10}}>
                <Avatar src={`${record.firstname}?size=32x32`} style={{width: 30, height: 30}} />
              </ListItemAvatar>
              <ListItemText
                primary={<div style={{fontSize: '0.6em', textOverflow: 'ellipsis'}}>{record.firstname} {record.lastname}</div>}
              />
            </ListItem>
          ))
        : null}
    </List>
  );
}
