import React from 'react';
import { Edit, SimpleForm, TextField } from 'react-admin';
import { InputGuesser } from "@api-platform/admin";
import { useGetOne } from "ra-core";

const EditReviews = (({ classes, ...props }) => {
  const { data } = useGetOne('users', props.id);

  return (
    <Edit {...props}>
      {data &&
        <SimpleForm redirect={`/users/${encodeURIComponent(data?.professional)}/show/reviews`} >
          <TextField label="Commentaire" source="comment"/>
          <TextField label="Note" source="rating"/>
          <InputGuesser label="Date de création" source="created_at" />
        </SimpleForm>
      }
    </Edit>
  );
});

export default EditReviews
