export const customErrorMessages = {
  'Incorrect username or password.': 'Veuillez saisir un mail et un mot de passe valide',
  'Reset Password' : 'Mot de passe oublié',
  'Enter your email' : 'Saisissez votre email',
  'CUSTOM_AUTH is not enabled for the client.' : 'Un problème de configuration ne permet pas de continuer. Veuillez contacter le service support.',
  'User does not exist.' : "L'utilisateur n'existe pas.",
  'The provided username does not correspond to a valid user in the user pool.' : 'L\'adresse email n\'est pas valide.',
  'The provided credentials (username or password) are incorrect' : 'L\'email ou le mot de passe n\'est pas valide.',
  'Invalid session for the user, session is expired.': 'Pour des raisons de sécurité, votre session a expiré. Veuillez actualiser la page et reconnecter.',
  'Code mismatch': 'Le code de vérification est incorrect',
  'Invalid code received for user': 'Le code de vérification est incorrect',
  "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6": "Le mot de passe doit faire au moins 6 caractères.",
  "Username cannot be empty": "Le nom d'utilisateur doit être renseigné",
  "An account with the given email already exists.": "Un compte avec cette adresse couriel existe déjà.",
  "User is disabled.": "L'utilisateur est désactivé.",
  "Access Token has been revoked": "Le jeton d'accès a été révoqué",
  'Password must have at least 8 characters': 'Le mot de passe doit faire au moins 8 caractères',
  'Password must have lower case letters': 'Le mot de passe doit contenir au moins une minuscule',
  'Password must have upper case letters': 'Le mot de passe doit contenir au moins une majuscule',
  'Password must have special characters': 'Le mot de passe doit contenir au moins un caractère spécial suivant ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < \' : ; | _ ~ ` + =',
  'Invalid verification code provided, please try again': 'Le code de vérification est incorrect',
  'Invalid session for the user, session can only be used once.': 'Pour des raisons de sécurité, votre session a expiré. Veuillez actualiser la page et reconnecter.',
};
