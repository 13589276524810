import React, {useEffect, useState} from 'react';
import { AutocompleteInput, ReferenceInput, required, NumberInput, minValue, maxLength } from 'react-admin';
import { InputGuesser, CreateGuesser } from "@api-platform/admin";
import OfferSelector from './components/OfferSelector';

const CreatePromotions = (({ classes, ...props }) => {
  const [skillerUri, setSkillerUri] = useState([]);
  const [isVolume, setIsVolume] = useState(false);
  const [selectedOfferUuid, setSelectedOfferUuid] = useState(null);
  const [isDivisible, setIsDivisible] = useState(false);

  const validateValue = (value) => {
    if (isVolume && (value > 100 || value < 0)) {
        return "La valeur en volume doit être infèrieur à 100";
    }
    return undefined;
  };

  let transform;
  if (selectedOfferUuid) {
    transform = data => {
      data.offer = '/offers/' + selectedOfferUuid;
      return data;
    };
  }

  return (
    <CreateGuesser  undoable={true} {...props} title={'Créer une promotion'} transform={transform}>
        <InputGuesser source="title" validate={[required()]} />
        <InputGuesser
          source="value"
          label="Valeur de la réduction"
          onWheel={() => document.activeElement.blur()}
          validate={[minValue(1), required(), validateValue]}
        />
        <InputGuesser
          source="charges_on_skiller"
          label="Charges appliquées au skiller"
        />
        <InputGuesser
          source="is_volume"
          label="Valeur en volume (%)"
          onChange={() => setIsVolume(!isVolume)}
          disabled={isDivisible}
        />
        <NumberInput
          source="min_paid_price"
          label="Prix minimum pour bénéficier de la réduction"
          onWheel={() => document.activeElement.blur()}
          validate={[minValue(1)]}
        />
        {(isVolume) &&
          <InputGuesser
            source="max_reduction_price"
            label="Réduction maximum (en euro)"
            validate={[minValue(1)]}
            onWheel={() => document.activeElement.blur()}
          />
        }
        <InputGuesser
          source="used_limit"
          label="Maximum de codes utilisables"
          validate={[minValue(1)]}
          onWheel={() => document.activeElement.blur()}
        />

        <InputGuesser
          source="limit_date"
          label="Date limite"
          validate={[required()]}
        />

      <InputGuesser
        source="is_divisible"
        label="Coupon sécable"
        onChange={() => setIsDivisible(!isDivisible)}
        disabled={isVolume}
      />

      <ReferenceInput
        label="Propriétaire du code promo"
        name={'users'}
        source={'owner_id'}
        reference={'users'}
      >
        <AutocompleteInput
          filterToQuery={searchText => ({ email: searchText})}
          source={"email"}
          optionText="email"
          label="Propriétaire du code promo"
          style={{ width: 300 }}
        />
      </ReferenceInput>

        <>(Pour applique le code promo à une offre, vous devez indiquer le mail du skiller)</>
        <ReferenceInput
          label="Adresse e-mail du skiller"
          reference="users"
          source="professional"
          allowEmpty={true}
          fullWidth
          name={'professional'}
        >
          <AutocompleteInput
            filterToQuery={searchText => ({ email: searchText, role:["ROLE_USER", "ROLE_PRO"]})}
            source={"email"}
            optionText="email"
            onChange={id => setSkillerUri(id || [])}

            label={"email du skiller (pour lier à une offre)"}
            style={{ width: 300 }}
          />
        </ReferenceInput>

        {skillerUri &&  skillerUri.length > 0 && (
          <OfferSelector selectedOfferUuid={selectedOfferUuid} setSelectedOfferUuid={setSelectedOfferUuid} skillerUri={skillerUri} />
        )}
    </CreateGuesser>
  );
});

export default CreatePromotions;