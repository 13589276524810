import {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import { BulkExportButton, useGetList } from 'react-admin';
import moment from "moment";
import { commandMail, commandPayment, commandSMS, reindexOffers } from "../../routeApi/commands";
import Badge from '../../components/badge/Badge';
import {
  Container,
  BadgePieContainer,
  A,
} from './Dashboard.styled';
import ListComponent from '../../components/list/ListComponent';
import {Box, Button, Grid, Tab, Tabs} from '@mui/material';
import BadgePie from '../../components/badgePie/BadgePie';
import { getUsersAndProfiles } from '../../routeApi/user';
import * as XLSX from 'xlsx';
import { getGroups } from '../../utils/CognitoHelpers';

const Dashboard = () => {
  const [filterDateValue, setfilterDateValue] = useState('thisWeek');
  const [filterDate, setfilterDate] = useState({begin: moment().startOf('isoWeek').format('YYYY-MM-DD'), end: moment().endOf('isoWeek').format('YYYY-MM-DD')});
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    getGroups()
      .then((groups) => setRoles(groups))
      .catch((error) => {
        console.error(error);
        setRoles([]);
      });
  }, []);

  const { data: skillers, total: nbSkillers } =
    useGetList(
      'users',
      {
        sort: {field: 'id', order: 'DESC'},
        pagination: false,
        filter: {
          roles: 'ROLE_PRO',
          "createdAt[after]": filterDate['begin'],
          "createdAt[before]": filterDate['end']
        }
      }
    );

  const { data: users, total: nbUsers } =
    useGetList(
      'users',
      {
        sort: {field: 'id', order: 'DESC'},
        pagination: false,
        filter: {
          itemsPerPage: 0,
          "createdAt[after]": filterDate['begin'],
          "createdAt[before]": filterDate['end']
        }
      }
    );

  const { total: nbOffers } =
    useGetList(
      'offers',
      {
        sort: {field: 'id', order: 'DESC'},
        pagination: false,
        filter: {
          "createdAt[after]": filterDate['begin'],
          "createdAt[before]": filterDate['end']
        }
      }
    );

  const {total: nbTransactions } =
    useGetList(
      'orders',
      {
        sort: { field: 'id', order: 'DESC' },
        pagination: false,
        filter: {
          "createdAt[after]": filterDate['begin'],
          "createdAt[before]": filterDate['end'],
          status: 'payout_completed'
        }
      }
    );


  const {total: nbOrders } =
    useGetList(
      'orders',
      {
        sort: { field: 'id', order: 'DESC' },
        pagination: false,
        filter: {itemsPerPage: 0, "createdAt[after]": filterDate['begin'], "createdAt[before]": filterDate['end'], status: 'payment_completed'}
      }
    );

  const exportUsersAndProfiles = () => {
    getUsersAndProfiles()
      .then(data => {
        const worksheetOffers = XLSX.utils.json_to_sheet(data.offers);
        const workbookOffers = XLSX.utils.book_new();

        const worksheetClients = XLSX.utils.json_to_sheet(data.clients);
        const workbookClients = XLSX.utils.book_new();

        const worksheetPros = XLSX.utils.json_to_sheet(data.pros);
        const workbookPros = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbookOffers, worksheetOffers, "offers");
        XLSX.writeFile(workbookOffers, "offers.xlsx");

        XLSX.utils.book_append_sheet(workbookClients, worksheetClients, "clients");
        XLSX.writeFile(workbookClients, "clients.xlsx");

        XLSX.utils.book_append_sheet(workbookPros, worksheetPros, "professionnels");
        XLSX.writeFile(workbookPros, "professionnel.xlsx");
      }
      )
  }

  const getUrlFiltersParameters = (filter) => {
    if (filter === undefined) {
      filter = "";
    }
    else {
      filter += ","
    }

    filter += "\"createdAt\":{\"after\":\""+filterDate['begin']+"\",\"before\":\""+filterDate['end']+"\"}";

    return "filter={" + filter + "}";
  }

  const handleChangeFilterDate = (event, newValue) => {
    setfilterDateValue(newValue);
    switch (newValue) {
      case 'thisWeek':
        setfilterDate({begin: moment().startOf('isoWeek').format('YYYY-MM-DD'), end: moment().endOf('isoWeek').format('YYYY-MM-DD')});
        break;
      case 'thisMonth':
        setfilterDate({begin: moment().startOf('month').format('YYYY-MM-DD'), end: moment().endOf('month').format('YYYY-MM-DD')});
        break;
      case 'thisYear':
        setfilterDate({begin: moment().startOf('year').format('YYYY-MM-DD'), end: moment().endOf('year').format('YYYY-MM-DD')});
        break;
      case 'all':
        setfilterDate({begin: moment('2021.01.01', 'YYYY.MM.DD').format('YYYY-MM-DD'), end: moment().endOf('month').format('YYYY-MM-DD')});
        break;
      case 'lastMonth':
        setfilterDate({begin: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), end: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')});
        break;
      case 'last2Month':
        setfilterDate({begin: moment().subtract(2, 'month').startOf('month').format('YYYY-MM-DD'), end: moment().subtract(2, 'month').endOf('month').format('YYYY-MM-DD')});
        break;
      default:
        break;
    }
  };
  return (
    <div>
      { process.env.REACT_APP_ENTRYPOINT !== "https://api.wooskill.com" &&
        <div>
          <Button onClick={ reindexOffers }>Reindexer les offres</Button>
          <Button onClick={ commandSMS }>Envoyer les SMS</Button>
          <Button onClick={ commandMail }>Envoyer les emails</Button>
          <Button onClick={ commandPayment }>Effectuer les paiements</Button>
          {roles.includes('ROLE_SUPER_ADMIN') &&
            <BulkExportButton onClick={() => exportUsersAndProfiles()}>Exporter toute les données </BulkExportButton>
          }
        </div>
      }
      {roles.includes('ROLE_SUPER_ADMIN') &&
        <div>
          <BulkExportButton onClick={() => exportUsersAndProfiles()}>Exporter toute les données </BulkExportButton>
        </div>
      }
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Card>
              <Badge title="Clients" number={nbUsers} colorPrimary="#7B62FF" colorSecondary="#DE9CF1" />
              <ListComponent list={users} />
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card>
              <Badge title="Skillers" number={nbSkillers} colorPrimary="#00B4FF" colorSecondary="#00F2F4" />
              <ListComponent list={skillers} />
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <Box style={{ borderBottom: 1, borderColor: 'divider', padding: 5 }}>
                <Tabs onChange={handleChangeFilterDate} value={filterDateValue} aria-label="basic tabs example">
                  <Tab label="Cette semaine" value={'thisWeek'} style={{textTransform: 'none', minWidth: 0, fontSize: '0.7em', padding: 7}} />
                  <Tab label="Ce mois" value={'thisMonth'} style={{textTransform: 'none', minWidth: 0, fontSize: '0.7em', padding: 7}} />
                  <Tab label="Cette annee" value={'thisYear'} style={{textTransform: 'none', minWidth: 0, fontSize: '0.7em', padding: 7}} />
                  <Tab label="Le mois dernier" value={'lastMonth'} style={{textTransform: 'none', minWidth: 0, fontSize: '0.7em', padding: 7}} />
                  <Tab label="Il y a 2 mois" value={'last2Month'} style={{textTransform: 'none', minWidth: 0, fontSize: '0.7em', padding: 7}} />
                  <Tab label="Tout le temps (!)" value={'all'} style={{textTransform: 'none', minWidth: 0, fontSize: '0.7em', padding: 7}} />
                </Tabs>
              </Box>
              <BadgePieContainer>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <A href={"/#/users?"+getUrlFiltersParameters()}><BadgePie title="Nombre de skillers" number={nbSkillers} colorPrimary="#00EFF6" colorSecondary="#00B4FF" /></A>
                  </Grid>
                  <Grid item xs={6}>
                    <A href={"/#/orders?"+getUrlFiltersParameters()}><BadgePie title="Nombre de commandes" number={nbOrders + nbTransactions} colorPrimary="#FF1D71" colorSecondary="#FFBF04" /></A>
                  </Grid>
                  <Grid item xs={6}>
                    <A href={"/#/orders?"+getUrlFiltersParameters("\"status\":\"payout_completed\"")}><BadgePie title="Nombre de transactions" number={nbTransactions} colorPrimary="#FFBF04" colorSecondary="#FF1D71" /></A>
                  </Grid>
                  <Grid item xs={6}>
                    <A href={"/#/offers?"+getUrlFiltersParameters()}><BadgePie title="Nombre d'offres" number={nbOffers} colorPrimary="#F91A7B" colorSecondary="#7857FA" /></A>
                  </Grid>
                </Grid>
              </BadgePieContainer>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Dashboard;
