import {getUserRelaysList} from '../replays/api';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {Alert, Button, Tooltip} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {DataGrid} from '@mui/x-data-grid';
import {useSearchParams} from 'react-router-dom';
import moment from 'moment/moment';

const USER_REPLAY_LIST_STATE_LOADING = 'loading';
const USER_REPLAY_LIST_STATE_LOADED = 'loaded';
const USER_REPLAY_LIST_STATE_ERROR = 'error';

export const UserReplays = ({userId, type, columnsRenderer}) => {
  const [state, setState] = useState(USER_REPLAY_LIST_STATE_LOADING);
  const [list, setList] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPage, setTotalPage] = useState(0);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const page = useMemo(() => parseInt(searchParams.get('page') || 1), [searchParams]);

  const loadUserReplays = useCallback(() => {
    setState(USER_REPLAY_LIST_STATE_LOADING);
    getUserRelaysList(userId, type).then(({list, pageSize, numberOfPages, isSuperAdmin}) => {
      setList(list)
      setPageSize(pageSize);
      setTotalPage(numberOfPages)
      setIsSuperAdmin(isSuperAdmin)
      setState(USER_REPLAY_LIST_STATE_LOADED);
    }).catch(error => {
      console.error(error);
      setState(USER_REPLAY_LIST_STATE_ERROR);
    });

  }, [userId, type]);

  useEffect(() => {
    if (!userId || !type) {
      return;
    }
    loadUserReplays();
  }, [userId, type]);


  const columns = useMemo(() => columnsRenderer(page, isSuperAdmin), [page, isSuperAdmin, columnsRenderer]);


  return <>
    {type === 'skiller' && <h3>Replays créé ou disponible par le Skiller</h3>}
    {type === 'client' && <h3>Replays accessible en lecture par le Client</h3>}

    {state === USER_REPLAY_LIST_STATE_LOADING && <CircularProgress/>}

    {state === USER_REPLAY_LIST_STATE_ERROR && <Alert severity="error">
      Impossible de récupérer la liste des replays de cet utilisateur.
      Veuillez vérifier que vous êtes bien connecté.
      <br/>
      <Button
        onClick={loadUserReplays}
        sx={{marginTop: 2}}
        variant="contained"
      >Recommencer</Button>
    </Alert>}

    {state === USER_REPLAY_LIST_STATE_LOADED && !list.length && <Alert severity="info">Aucun replay disponible</Alert>}

    {(state === USER_REPLAY_LIST_STATE_LOADED && list.length > 0) &&
      <DataGrid
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize,
            },
          },
        }}
        loading={state === USER_REPLAY_LIST_STATE_LOADING}
        rows={list}
        disableColumnFilter
        disableColumnMenu
        hideFooter
      />}

    {state === USER_REPLAY_LIST_STATE_LOADED && <div style={{textAlign: 'center'}}>
      {page > 1 && <Button
        onClick={() => setSearchParams(current => ({ ... current, page: page - 1 }))}
        sx={{float: 'left', marginTop: 1}}
        variant="outlined"
      >
        <KeyboardArrowLeftIcon sx={{marginRight: 1}}/> Précédent
      </Button>}
      {page < totalPage && <Button
        onClick={() => setSearchParams(current => ({ ... current, page: page + 1 }))}
        sx={{float: 'right', marginTop: 1}}
        variant="outlined"
      >
        Suivant <KeyboardArrowRightIcon sx={{marginLeft: 1}}/>
      </Button>}
      {totalPage > 1 && <div style={{paddingTop: 15}}>Page {page} / {totalPage}</div>}
    </div>}
  </>;
}
