import React from 'react';
import { InputGuesser, CreateGuesser } from "@api-platform/admin";
import { PasswordInput, SelectInput, required } from 'react-admin';
import { sexeChoices } from '../utils';

const CreateUsers = (({ classes, ...props }) => (
  <CreateGuesser undoable={true} {...props} title={'Créer un utilisateur'}>
    <InputGuesser source={'firstname'} validate={[required()]} />
    <InputGuesser source={'lastname'} validate={[required()]} />
    <InputGuesser source={'email'} validate={[required()]} />
    <PasswordInput
      source="password"
      inputProps={{ autocomplete: 'current-password' }}
      validate={[required()]}
      helperText="ra.validation.helper_password"
    />

    <SelectInput
      source="gender"
      choices={sexeChoices}
      validate={[required()]}
    />
    <InputGuesser source={'birth_date'} />
    <InputGuesser source={'phone_country_code'} />
    <InputGuesser source={'phone_number'} />
  </CreateGuesser>
));

export default CreateUsers;
