import { fetchData } from '../overrides/Fetch';
import {getAuthorizationHeader} from '../utils/CognitoHelpers';

export const fetchSpecialities = async filters => {
	const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/specialities?${filters}`;
	let headers;

	headers = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json',
      ...(await getAuthorizationHeader()),
    },
	};

	return fetchData(entrypoint, headers);
}

export const fetchSpecialitiesByDomain = async id => {
	const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/specialities/?domains=${id}`;
	let headers;

	headers = {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json',
      ...(await getAuthorizationHeader()),
    },
	};

	return fetchData(entrypoint, headers);
}
