import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Chip, Tooltip} from '@mui/material';

export const ReplayTaskStatus = ({value}) => {
  switch (value) {
    case 'fail':
      return <Chip
        color="error"
        label="Erreur"
      />
    case 'in-progress':
      return <Chip
        color="warning"
        label="En cours..."
      />
    case 'success':
      return <Chip
        color="success"
        label="Terminé"
      />
    case 'wait':
      return <Chip
        label="En attente"
      />
  }
  return <Chip label={`Etat indéterminé : ${value}`} />
}
