import React from 'react';
import { required, BooleanInput } from 'react-admin';
import { InputGuesser, CreateGuesser } from "@api-platform/admin";

const CreateSpeciality = (({ classes, ...props }) => (
  <CreateGuesser undoable={true} {...props} title={'Créer une spécialité'}>
    <InputGuesser source={'name'} validate={[required()]} />
    <BooleanInput label="Activé" source="enabled" />
  </CreateGuesser>
));

export default CreateSpeciality;
